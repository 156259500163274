import React, { useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  Grid,
  Button,
  Card,
  CardContent,
  CardActions,
  Tab,
  Tabs,
  Alert,
  useTheme,
  useMediaQuery,
  Select,
  MenuItem
} from '@mui/material';
import {
  Assessment,
  TrendingUp,
  AccountBalance,
  CalendarToday
} from '@mui/icons-material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import TransactionReport from './TransactionReport';
import ProfitLossReport from './ProfitLossReport';
import CashflowReport from './CashflowReport';
import TaxReport from './TaxReport';

const ReportsDashboard = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [error, setError] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const tabs = [
    { label: 'Transactions', icon: <Assessment />, component: TransactionReport },
    { label: 'Profit & Loss', icon: <TrendingUp />, component: ProfitLossReport },
    { label: 'Cashflow', icon: <AccountBalance />, component: CashflowReport },
    { label: 'Tax', icon: <CalendarToday />, component: TaxReport }
  ];

  const handleTabChange = (event, newValue) => {
    setError(null);
    setSelectedTab(newValue);
  };

  const handleMobileChange = (event) => {
    setError(null);
    setSelectedTab(event.target.value);
  };

  const renderReport = (tabIndex) => {
    try {
      const ReportComponent = tabs[tabIndex].component;
      return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ReportComponent />
        </LocalizationProvider>
      );
    } catch (err) {
      setError('Failed to load report component');
      console.error('Report loading error:', err);
      return <Alert severity="error">Failed to load report. Please try again.</Alert>;
    }
  };

  return (
    <Box sx={{ p: { xs: 2, sm: 3 } }}>
      <Typography variant="h4" gutterBottom>
        Financial Reports
      </Typography>
      
      {isMobile ? (
        // Mobile Dropdown
        <Box sx={{ mb: 3 }}>
          <Select
            value={selectedTab}
            onChange={handleMobileChange}
            fullWidth
            variant="outlined"
            sx={{
              '& .MuiSelect-select': {
                display: 'flex',
                alignItems: 'center',
                gap: 1
              }
            }}
          >
            {tabs.map((tab, index) => (
              <MenuItem key={index} value={index}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  {React.cloneElement(tab.icon, { fontSize: 'small' })}
                  {tab.label}
                </Box>
              </MenuItem>
            ))}
          </Select>
        </Box>
      ) : (
        // Desktop Tabs
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          textColor="primary"
          indicatorColor="primary"
          sx={{ 
            mb: 3,
            '& .MuiTab-root': {
              minHeight: 48,
              padding: '6px 16px',
            }
          }}
          variant="scrollable"
          scrollButtons="auto"
        >
          {tabs.map((tab, index) => (
            <Tab 
              key={index}
              icon={tab.icon}
              label={tab.label}
              iconPosition="start"
            />
          ))}
        </Tabs>
      )}

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <Box sx={{ mt: 2 }}>
        {renderReport(selectedTab)}
      </Box>
    </Box>
  );
};

export default ReportsDashboard;