import errorHandler from './utils/errorHandler';
import axios from 'axios';

const api = axios.create({
    baseURL: '/api',
    headers: {
        'Content-Type': 'application/json'
    }
});

const logApiCall = (config) => {
    console.log('API Request Details:', {
        method: config.method,
        url: config.url,
        baseURL: config.baseURL,
        fullURL: `${config.baseURL}${config.url}`,
        hasToken: !!localStorage.getItem('access_token')
    });
};

const handleAuthError = () => {
    localStorage.clear();
    window.location.href = '/login';
};

// Request interceptor
api.interceptors.request.use(
    (config) => {
        // Don't add token for login
        if (config.url === '/auth/login') {
            delete config.headers.Authorization;
            logApiCall(config);
            return config;
        }

        const accessToken = localStorage.getItem('access_token');
        if (accessToken && accessToken !== 'undefined') {
            config.headers.Authorization = `Bearer ${accessToken}`;
        } else {
            delete config.headers.Authorization;
        }
        
        logApiCall(config);
        return config;
    },
    (error) => Promise.reject(error)
);

// Response interceptor
api.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;
        
        // Don't retry for login endpoint
        if (originalRequest.url === '/auth/login') {
            return Promise.reject(error);
        }

        if (error.response?.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            
            try {
                const refreshToken = localStorage.getItem('refresh_token');
                if (!refreshToken) {
                    throw new Error('No refresh token available');
                }

                const response = await axios.post('/api/auth/refresh', {
                    refresh_token: refreshToken
                });

                if (response.data.access_token) {
                    localStorage.setItem('access_token', response.data.access_token);
                    api.defaults.headers.common['Authorization'] = `Bearer ${response.data.access_token}`;
                    originalRequest.headers.Authorization = `Bearer ${response.data.access_token}`;
                    return api(originalRequest);
                }
            } catch (refreshError) {
                handleAuthError();
                return Promise.reject(refreshError);
            }
        }

        return Promise.reject(error);
    }
);

export default api;