import React, { useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  Grid,
  Button,
  CircularProgress,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Card,
  CardContent,
  Divider,
  LinearProgress,
  Tooltip
} from '@mui/material';
import {
  Download,
  Refresh,
  Calculate,
  Payment,
  AccountBalance,
  Warning,
  TrendingUp
} from '@mui/icons-material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import api from './axiosConfig';

const TaxReport = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [report, setReport] = useState(null);

  const handleGenerateReport = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await api.get('/reports/tax');
      setReport(response.data);
    } catch (err) {
      setError(err.response?.data?.error || 'Failed to generate tax report');
    } finally {
      setLoading(false);
    }
  };

  const handleExport = async (format) => {
    try {
      const response = await api.get('/reports/tax/export', {
        params: { format },
        responseType: 'blob'
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `tax_report_${report.tax_year.year}.${format.toLowerCase()}`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (err) {
      setError('Failed to export report');
    }
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: 'GBP'
    }).format(amount);
  };

  const calculateProgressPercentage = () => {
    if (!report) return 0;
    const startDate = new Date(report.tax_year.start_date);
    const endDate = new Date(report.tax_year.end_date);
    const now = new Date();
    const total = endDate - startDate;
    const elapsed = now - startDate;
    return Math.min(100, Math.max(0, (elapsed / total) * 100));
  };

  return (
    <Box>
      {/* Controls */}
      <Paper sx={{ p: 3, mb: 3 }}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={6}>
            <Typography variant="h6">
              Tax Year {report?.tax_year.year}/{report?.tax_year.year + 1}
            </Typography>
            {report && (
              <Box sx={{ mt: 2 }}>
                <LinearProgress 
                  variant="determinate" 
                  value={calculateProgressPercentage()} 
                  sx={{ height: 10, borderRadius: 5 }}
                />
                <Typography variant="caption" color="textSecondary">
                  Tax year progress
                </Typography>
              </Box>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
              <Button
                variant="contained"
                onClick={handleGenerateReport}
                disabled={loading}
                startIcon={loading ? <CircularProgress size={20} /> : <Refresh />}
              >
                Generate Report
              </Button>
              <Button
                variant="outlined"
                onClick={() => handleExport('PDF')}
                disabled={!report}
                startIcon={<Download />}
              >
                Export PDF
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Paper>

      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}

      {report && (
        <Box>
          {/* Summary Cards */}
          <Grid container spacing={3} sx={{ mb: 3 }}>
            <Grid item xs={12} md={4}>
              <Card>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Calculate color="primary" />
                    <Typography variant="h6">Total Tax Due</Typography>
                  </Box>
                  <Typography variant="h4" sx={{ mt: 2 }}>
                    {formatCurrency(report.income_tax.total_tax_due)}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Effective rate: {report.income_tax.effective_tax_rate.toFixed(1)}%
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Payment color="info" />
                    <Typography variant="h6">Monthly Requirement</Typography>
                  </Box>
                  <Typography variant="h4" sx={{ mt: 2 }}>
                    {formatCurrency(report.payment_requirements.monthly_remaining)}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {report.payment_requirements.remaining_months} months remaining
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <AccountBalance color="warning" />
                    <Typography variant="h6">National Insurance</Typography>
                  </Box>
                  <Typography variant="h4" sx={{ mt: 2 }}>
                    {formatCurrency(report.national_insurance.total_ni_due)}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Total NI contribution
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          {/* Income Breakdown */}
          <Paper sx={{ p: 3, mb: 3 }}>
            <Typography variant="h6" gutterBottom>
              Income Summary
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <Box sx={{ p: 2, bgcolor: 'background.default', borderRadius: 1 }}>
                  <Typography variant="subtitle2">Gross Income</Typography>
                  <Typography variant="h5">
                    {formatCurrency(report.income_summary.gross_income)}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box sx={{ p: 2, bgcolor: 'background.default', borderRadius: 1 }}>
                  <Typography variant="subtitle2">Total Expenses</Typography>
                  <Typography variant="h5">
                    {formatCurrency(report.income_summary.total_expenses)}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box sx={{ p: 2, bgcolor: 'background.default', borderRadius: 1 }}>
                  <Typography variant="subtitle2">Taxable Income</Typography>
                  <Typography variant="h5">
                    {formatCurrency(report.income_summary.taxable_income)}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Paper>

          {/* Tax Bands Breakdown */}
          <Paper sx={{ p: 3, mb: 3 }}>
            <Typography variant="h6" gutterBottom>
              Income Tax Breakdown
            </Typography>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Tax Band</TableCell>
                    <TableCell align="right">Amount</TableCell>
                    <TableCell align="right">Rate</TableCell>
                    <TableCell align="right">Tax Due</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {report.income_tax.breakdown.map((band, index) => (
                    <TableRow key={index}>
                      <TableCell>{band.band}</TableCell>
                      <TableCell align="right">{formatCurrency(band.amount)}</TableCell>
                      <TableCell align="right">{band.tax_rate}%</TableCell>
                      <TableCell align="right">{formatCurrency(band.tax)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>

          {/* National Insurance Breakdown */}
          <Paper sx={{ p: 3, mb: 3 }}>
            <Typography variant="h6" gutterBottom>
              National Insurance Breakdown
            </Typography>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Band</TableCell>
                    <TableCell align="right">Amount</TableCell>
                    <TableCell align="right">Rate</TableCell>
                    <TableCell align="right">Contribution</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {report.national_insurance.breakdown.map((band, index) => (
                    <TableRow key={index}>
                      <TableCell>{band.band}</TableCell>
                      <TableCell align="right">{formatCurrency(band.amount)}</TableCell>
                      <TableCell align="right">{band.rate}%</TableCell>
                      <TableCell align="right">{formatCurrency(band.contribution)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>

          {/* Payment Schedule */}
          <Paper sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              Payment Requirements
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Card>
                  <CardContent>
                    <Typography variant="subtitle1" gutterBottom>
                      Monthly Set-aside Recommendation
                    </Typography>
                    <Typography variant="h4">
                      {formatCurrency(report.payment_requirements.monthly_requirement)}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                      Based on annual liability of {formatCurrency(report.payment_requirements.total_liability)}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card>
                  <CardContent>
                    <Typography variant="subtitle1" gutterBottom>
                      Adjusted Monthly Requirement
                    </Typography>
                    <Typography variant="h4">
                      {formatCurrency(report.payment_requirements.monthly_remaining)}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                      {report.payment_requirements.remaining_months} months remaining in tax year
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>

            {report.payment_requirements.monthly_remaining > 
             report.payment_requirements.monthly_requirement * 1.2 && (
              <Alert severity="warning" sx={{ mt: 3 }}>
                <Typography variant="subtitle2">
                  Your monthly requirement has increased significantly
                </Typography>
                Consider increasing your tax savings to meet the adjusted monthly target.
              </Alert>
            )}
          </Paper>
        </Box>
      )}
    </Box>
  );
};

export default TaxReport;