import React, { useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  Button,
  Tabs,
  Tab,
  Alert,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';
import Papa from 'papaparse';
import api from '../axiosConfig';

const EmployeeImport = ({ setMessage, setMessageType }) => {
  const [activeTab, setActiveTab] = useState('INSTRUCTIONS');
  const [validationErrors, setValidationErrors] = useState([]);
  const [importResults, setImportResults] = useState(null);

  // Constants
  const VALID_ROLES = ['ACCOUNT_OWNER', 'ACCOUNT_MANAGER', 'ACCOUNT_USER'];

  // Validation functions
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return email && re.test(email);
  };

  const validateNINumber = (ni) => {
    const re = /^[A-Z]{2}[0-9]{6}[A-Z]$/i;
    return ni && re.test(ni.replace(/\s/g, ''));
  };

  const validateUKPostcode = (postcode) => {
    const re = /^[A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}$/i;
    return postcode && re.test(postcode);
  };

  const validatePhoneNumber = (phone) => {
    const re = /^(\+44|0)\d{10}$/;
    return phone && re.test(phone.replace(/\s/g, ''));
  };

  const validateDate = (dateStr) => {
    if (!dateStr) return false;
    const ukFormat = /^(\d{2})\/(\d{2})\/(\d{4})$/;  // DD/MM/YYYY
    let day, month, year;
    
    if (ukFormat.test(dateStr)) {
      [, day, month, year] = dateStr.match(ukFormat);
    } else {
      return false;
    }
    
    const date = new Date(year, month - 1, day);
    if (isNaN(date.getTime())) return false;
    
    return date.getDate() === parseInt(day) &&
           (date.getMonth() + 1) === parseInt(month) &&
           date.getFullYear() === parseInt(year);
  };

  const convertDateFormat = (ukDate) => {
    if (!ukDate) return ukDate;
    const [day, month, year] = ukDate.split('/');
    return `${year}-${month}-${day}`;
  };

  const validateDecimal = (value, min, max) => {
    const num = parseFloat(value);
    return !isNaN(num) && num >= min && num <= max;
  };

  const REQUIRED_FIELDS = [
    'name',
    'email',
    'address_line1',
    'postcode',
    'contact_number',
    'emergency_contact',
    'emergency_number',
    'date_of_birth',
    'national_insurance_number',
    'start_date',
    'hourly_rate',
    'role'
  ];

  const validateEmployee = (data) => {
    const errors = [];

    // Required fields check
    REQUIRED_FIELDS.forEach(field => {
      if (!data[field]) {
        errors.push(`Missing required field: ${field}`);
      }
    });

    // Format validations
    if (data.email && !validateEmail(data.email)) {
      errors.push('Invalid email format');
    }

    if (data.role && !VALID_ROLES.includes(data.role)) {
      errors.push(`Invalid role. Must be one of: ${VALID_ROLES.join(', ')}`);
    }

    if (data.national_insurance_number && !validateNINumber(data.national_insurance_number)) {
      errors.push('Invalid National Insurance number format (e.g., AB123456C)');
    }

    if (data.postcode && !validateUKPostcode(data.postcode)) {
      errors.push('Invalid UK postcode format');
    }

    if (data.contact_number && !validatePhoneNumber(data.contact_number)) {
      errors.push('Invalid contact number format (must be UK number)');
    }

    if (data.emergency_number && !validatePhoneNumber(data.emergency_number)) {
      errors.push('Invalid emergency contact number format (must be UK number)');
    }

    if (data.date_of_birth && !validateDate(data.date_of_birth)) {
      errors.push('Invalid date of birth format (use DD/MM/YYYY)');
    }

    if (data.start_date && !validateDate(data.start_date)) {
      errors.push('Invalid start date format (use DD/MM/YYYY)');
    }

    if (data.hourly_rate && !validateDecimal(data.hourly_rate, 0, 1000)) {
      errors.push('Invalid hourly rate (must be between 0 and 1000)');
    }

    if (data.commission_services && !validateDecimal(data.commission_services, 0, 100)) {
      errors.push('Invalid services commission (must be between 0 and 100)');
    }

    if (data.commission_products && !validateDecimal(data.commission_products, 0, 100)) {
      errors.push('Invalid products commission (must be between 0 and 100)');
    }

    if (data.full_time_equivalent && !validateDecimal(data.full_time_equivalent, 0, 1)) {
      errors.push('Invalid FTE (must be between 0 and 1)');
    }

    return errors;
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleDownloadTemplate = () => {
    const templateContent = 'name,email,address_line1,address_line2,address_line3,postcode,contact_number,' +
      'emergency_contact,emergency_number,date_of_birth,national_insurance_number,start_date,hourly_rate,' +
      'commission_services,commission_products,role,full_time_equivalent\n' +
      'John Smith,john@example.com,"123 Street","Area","City",AB12 3CD,07123456789,' +
      'Jane Smith,07987654321,01/01/1990,AB123456C,01/01/2024,12.50,10,5,ACCOUNT_USER,1';

    const blob = new Blob([templateContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'employee_import_template.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    // Reset previous results and errors
    setValidationErrors([]);
    setImportResults(null);

    Papa.parse(file, {
      header: true,
      skipEmptyRows: true,
      complete: async (results) => {
        const allErrors = [];
        const validData = [];

        // Validate each row
        results.data.forEach((row, index) => {
          if (Object.values(row).every(value => !value)) {
            return;
          }

          const rowErrors = validateEmployee(row);
          if (rowErrors.length > 0) {
            allErrors.push(`Row ${index + 1}: ${rowErrors.join(', ')}`);
          } else {
            // Convert dates before adding to valid data
            const processedRow = {
              ...row,
              date_of_birth: convertDateFormat(row.date_of_birth),
              start_date: convertDateFormat(row.start_date)
            };
            validData.push(processedRow);
          }
        });

        if (allErrors.length > 0) {
          setValidationErrors(allErrors);
          setMessage('Validation errors found. Please check the errors below and try again.');
          setMessageType('error');
          return;
        }

        try {
          const response = await api.post('/employees/import', validData);
          const results = response.data;
          setImportResults(results);

          if (results.successful_imports === 0) {
            setMessageType('error');
            if (results.errors && results.errors.length > 0) {
              setValidationErrors(results.errors);
              setMessage('Import failed. Please check the errors below.');
            } else {
              setMessage('Import failed. No employees were imported.');
            }
          } else {
            if (results.failed_imports > 0) {
              setMessageType('warning');
              setMessage(`Imported ${results.successful_imports} employees with ${results.failed_imports} failures.`);
              if (results.errors) {
                setValidationErrors(results.errors);
              }
            } else {
              setMessageType('success');
              setMessage(`Successfully imported ${results.successful_imports} employees!`);
            }
          }

          // Clear file input
          event.target.value = '';

        } catch (error) {
          console.error('Error uploading file:', error);
          setMessageType('error');
          if (error.response?.data?.errors) {
            setValidationErrors(error.response.data.errors);
            setMessage('Import failed. Please check the errors below.');
          } else {
            setMessage('Error importing employees. Please try again.');
          }
        }
      },
      error: (error) => {
        console.error('Error parsing CSV:', error);
        setMessage('Error reading CSV file. Please check the format and try again.');
        setMessageType('error');
      }
    });
  };

  return (
    <Paper elevation={3} sx={{ p: 3, maxWidth: 1200, margin: '0 auto' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h5">Employee Import</Typography>
      </Box>

      <Box sx={{ mb: 3 }}>
        <Tabs 
          value={activeTab} 
          onChange={handleTabChange}
          sx={{
            borderBottom: 1,
            borderColor: 'divider'
          }}
        >
          <Tab label="Import Instructions" value="INSTRUCTIONS" />
          <Tab label="Import" value="IMPORT" />
        </Tabs>
      </Box>

      {activeTab === 'INSTRUCTIONS' && (
        <Box>
          <Typography variant="h6" gutterBottom>Before You Import</Typography>
          <Alert severity="info" sx={{ mb: 3 }}>
            <Typography variant="subtitle2">Important Requirements:</Typography>
            <ul>
              <li>National Insurance numbers must be in correct format (e.g., AB123456C)</li>
              <li>Dates must be in DD/MM/YYYY format (e.g., 01/01/2024)</li>
              <li>Commission rates should be percentages between 0 and 100</li>
              <li>FTE (Full Time Equivalent) should be between 0 and 1</li>
              <li>UK phone numbers only (starting with 07, 01, 02, or +44)</li>
              <li>Valid UK postcodes required</li>
            </ul>
          </Alert>

          <Alert severity="warning" sx={{ mb: 3 }}>
            <Typography variant="subtitle2">Valid Role Options:</Typography>
            <Typography variant="body2">
              The role field must contain one of the following values exactly as shown:
            </Typography>
            <ul>
              <li>ACCOUNT_OWNER - Full system access</li>
              <li>ACCOUNT_MANAGER - Manager level access</li>
              <li>ACCOUNT_USER - Standard user access</li>
            </ul>
          </Alert>
        </Box>
      )}

      {activeTab === 'IMPORT' && (
        <Box>
          <Box>
            <Typography variant="subtitle1" gutterBottom>Import Instructions</Typography>
            <Alert severity="info" sx={{ mb: 3 }}>
              <Typography variant="body2">
                1. Download the template below
                <br />
                2. Fill in your employee data following the format requirements
                <br />
                3. Save and upload your completed file
                <br />
                All fields marked with * are required.
              </Typography>
            </Alert>

            {validationErrors.length > 0 && (
              <Alert severity="error" sx={{ mb: 3 }}>
                <Typography variant="subtitle2">Validation Errors:</Typography>
                <ul>
                  {validationErrors.map((error, index) => (
                    <li key={index}>{error}</li>
                  ))}
                </ul>
              </Alert>
            )}

            {importResults && (
              <Alert 
                severity={importResults.successful_imports > 0 ? 
                  (importResults.failed_imports > 0 ? 'warning' : 'success') : 
                  'error'
                } 
                sx={{ mb: 3 }}
              >
                <Typography variant="subtitle2">Import Results:</Typography>
                <ul>
                  <li>Successfully imported: {importResults.successful_imports}</li>
                  {importResults.failed_imports > 0 && (
                    <li>Failed imports: {importResults.failed_imports}</li>
                  )}
                  {importResults.duplicate_employees > 0 && (
                    <li>Duplicate employees found: {importResults.duplicate_employees}</li>
                  )}
                </ul>
              </Alert>
            )}

            <Button
              startIcon={<DownloadIcon />}
              variant="outlined"
              onClick={handleDownloadTemplate}
              sx={{ mt: 2, mb: 4 }}
            >
              DOWNLOAD TEMPLATE
            </Button>
          </Box>

          <Box>
            <Typography variant="subtitle1" gutterBottom>Import Data</Typography>
            <input
              type="file"
              accept=".csv"
              onChange={handleFileUpload}
              style={{ display: 'none' }}
              id="csv-file-input"
            />
            <label htmlFor="csv-file-input">
              <Button
                startIcon={<UploadIcon />}
                variant="contained"
                component="span"
              >
                Choose CSV File
              </Button>
            </label>
          </Box>
        </Box>
      )}
    </Paper>
  );
};

export default EmployeeImport;