import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import api from './axiosConfig';
import './adminstyles.css';

const PasswordReset = () => {
    const { token } = useParams();
    const navigate = useNavigate();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [isValid, setIsValid] = useState(false);

    useEffect(() => {
    const verifyToken = async () => {
        try {
            await api.get(`/auth/verify_reset_token/${token}`);
            setIsValid(true);
        } catch (error) {
            setMessage('Invalid or expired reset link.');
            setTimeout(() => navigate('/login'), 3000);
        }
    };
    verifyToken();
}, [token, navigate]);

const handleSubmit = async (event) => {
    event.preventDefault();
    if (password !== confirmPassword) {
        setMessage('Passwords do not match');
        return;
    }

    try {
        const response = await api.post('/auth/reset_password', { 
            token, 
            password 
        });
        setMessage('Password reset successfully. Redirecting to login...');
        setTimeout(() => navigate('/login'), 3000);
    } catch (error) {
        console.error('Error resetting password:', error);
        setMessage(error.response?.data?.error || 'An error occurred while resetting the password.');
    }
};

    if (!isValid) {
        return <div className="message-container">{message}</div>;
    }

    return (
        <div className="password-reset-container">
            <form onSubmit={handleSubmit}>
                <h2>Reset Password</h2>
                <div className="form-group">
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Enter new password"
                        required
                        minLength="8"
                    />
                </div>
                <div className="form-group">
                    <input
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        placeholder="Confirm new password"
                        required
                        minLength="8"
                    />
                </div>
                <button type="submit">Reset Password</button>
                {message && <div className="message">{message}</div>}
            </form>
        </div>
    );
};

export default PasswordReset;