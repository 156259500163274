import React, { useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  Grid,
  Button,
  TextField,
  MenuItem,
  CircularProgress,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Card,
  CardContent,
} from '@mui/material';
import {
  Download,
  Refresh,
  TrendingUp,
  TrendingDown,
  PieChart,
  CompareArrows
} from '@mui/icons-material';
import DatePickerComponent from './DatePickerComponent';
import dayjs from 'dayjs';
import api from './axiosConfig';

const ProfitLossReport = () => {
  const [startDate, setStartDate] = useState(dayjs().startOf('month'));
  const [endDate, setEndDate] = useState(dayjs().endOf('month'));
  const [exportFormat, setExportFormat] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [report, setReport] = useState(null);

  const handleGenerateReport = async () => {
  setLoading(true);
  setError(null);

  try {
    const response = await api.get('/reports/profit-loss', {  // or '/reports/cashflow'
      params: {
        start_date: startDate.format('YYYY-MM-DD'),  // Ensure ISO format
        end_date: endDate.format('YYYY-MM-DD'),      // Ensure ISO format
      }
    });

    setReport(response.data);
  } catch (err) {
    setError(err.response?.data?.error || 'Failed to generate report');
  } finally {
    setLoading(false);
  }
};

  const handleExport = async (format) => {
    try {
      const response = await api.get('/reports/profit-loss', {
        params: {
          start_date: startDate.format('YYYY-MM-DD'),  // Ensure ISO format
          end_date: endDate.format('YYYY-MM-DD'),      // Ensure ISO format
          export_format: format
        },
        responseType: 'blob'
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `profit_loss_report.${format.toLowerCase()}`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (err) {
      setError('Failed to export report');
    }
  };

  return (
    <Box>
      <Paper sx={{ p: 3, mb: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <DatePickerComponent
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
            />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
              <Button
                variant="contained"
                onClick={handleGenerateReport}
                disabled={loading}
                startIcon={loading ? <CircularProgress size={20} /> : <Refresh />}
              >
                Generate Report
              </Button>
              <TextField
                select
                label="Export Format"
                value={exportFormat}
                onChange={(e) => setExportFormat(e.target.value)}
                sx={{ minWidth: 120 }}
              >
                <MenuItem value="CSV">CSV</MenuItem>
                <MenuItem value="EXCEL">Excel</MenuItem>
              </TextField>
              <Button
                variant="outlined"
                onClick={() => handleExport(exportFormat)}
                disabled={!report || !exportFormat}
                startIcon={<Download />}
              >
                Export
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Paper>

      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}

      {report && (
        <Box>
          {/* Summary Cards */}
          <Grid container spacing={3} sx={{ mb: 3 }}>
            <Grid item xs={12} md={3}>
              <Card>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <TrendingUp color="success" />
                    <Typography variant="h6">Gross Income</Typography>
                  </Box>
                  <Typography variant="h4" sx={{ mt: 2 }}>
                    £{report.income.totals.gross.toFixed(2)}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={3}>
              <Card>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <TrendingDown color="error" />
                    <Typography variant="h6">Total Expenses</Typography>
                  </Box>
                  <Typography variant="h4" sx={{ mt: 2 }}>
                    £{report.expenses.totals.gross.toFixed(2)}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={3}>
              <Card>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <PieChart color="primary" />
                    <Typography variant="h6">Net Profit</Typography>
                  </Box>
                  <Typography 
                    variant="h4" 
                    sx={{ 
                      mt: 2,
                      color: report.metrics.net_profit >= 0 ? 'success.main' : 'error.main'
                    }}
                  >
                    £{report.metrics.net_profit.toFixed(2)}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={3}>
              <Card>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <CompareArrows color="info" />
                    <Typography variant="h6">Profit Margin</Typography>
                  </Box>
                  <Typography 
                    variant="h4" 
                    sx={{ 
                      mt: 2,
                      color: report.metrics.profit_margin_percentage >= 0 
                        ? 'success.main' 
                        : 'error.main'
                    }}
                  >
                    {report.metrics.profit_margin_percentage.toFixed(1)}%
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          {/* Income Breakdown */}
          <Paper sx={{ p: 3, mb: 3 }}>
            <Typography variant="h6" gutterBottom>
              Income Breakdown
            </Typography>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Type</TableCell>
                    <TableCell align="right">Gross Amount</TableCell>
                    <TableCell align="right">VAT</TableCell>
                    <TableCell align="right">Net Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {report.income.breakdown.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{item.type}</TableCell>
                      <TableCell align="right">
                        £{item.gross_amount.toFixed(2)}
                      </TableCell>
                      <TableCell align="right">
                        £{item.vat_amount.toFixed(2)}
                      </TableCell>
                      <TableCell align="right">
                        £{item.net_amount.toFixed(2)}
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold' }}>Total</TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                      £{report.income.totals.gross.toFixed(2)}
                    </TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                      £{report.income.totals.vat.toFixed(2)}
                    </TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                      £{report.income.totals.net.toFixed(2)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>

          {/* Expense Breakdown */}
          <Paper sx={{ p: 3, mb: 3 }}>
            <Typography variant="h6" gutterBottom>
              Expense Breakdown
            </Typography>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Category</TableCell>
                    <TableCell align="right">Gross Amount</TableCell>
                    <TableCell align="right">VAT</TableCell>
                    <TableCell align="right">Net Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {report.expenses.breakdown.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{item.category}</TableCell>
                      <TableCell align="right">
                        £{item.gross_amount.toFixed(2)}
                      </TableCell>
                      <TableCell align="right">
                        £{item.vat_amount.toFixed(2)}
                      </TableCell>
                      <TableCell align="right">
                        £{item.net_amount.toFixed(2)}
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold' }}>Total</TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                      £{report.expenses.totals.gross.toFixed(2)}
                    </TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                      £{report.expenses.totals.vat.toFixed(2)}
                    </TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                      £{report.expenses.totals.net.toFixed(2)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>

          {/* Comparative Analysis */}
          {report.comparative_data && (
            <Paper sx={{ p: 3 }}>
              <Typography variant="h6" gutterBottom>
                Comparative Analysis with Previous Period
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <Card>
                    <CardContent>
                      <Typography variant="subtitle1">Income Change</Typography>
                      <Typography 
                        variant="h5"
                        sx={{ 
                          color: report.comparative_data.changes.income.percentage >= 0 
                            ? 'success.main' 
                            : 'error.main' 
                        }}
                      >
                        {report.comparative_data.changes.income.percentage.toFixed(1)}%
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        £{report.comparative_data.changes.income.amount.toFixed(2)}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Card>
                    <CardContent>
                      <Typography variant="subtitle1">Expense Change</Typography>
                      <Typography 
                        variant="h5"
                        sx={{ 
                          color: report.comparative_data.changes.expenses.percentage <= 0 
                            ? 'success.main' 
                            : 'error.main' 
                        }}
                      >
                        {report.comparative_data.changes.expenses.percentage.toFixed(1)}%
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        £{report.comparative_data.changes.expenses.amount.toFixed(2)}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Card>
                    <CardContent>
                      <Typography variant="subtitle1">Profit Change</Typography>
                      <Typography 
                        variant="h5"
                        sx={{ 
                          color: report.comparative_data.changes.net_profit.percentage >= 0 
                            ? 'success.main' 
                            : 'error.main' 
                        }}
                      >
                        {report.comparative_data.changes.net_profit.percentage.toFixed(1)}%
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        £{report.comparative_data.changes.net_profit.amount.toFixed(2)}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Paper>
          )}
        </Box>
      )}
    </Box>
  );
};

export default ProfitLossReport;