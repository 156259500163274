import errorHandler from './utils/errorHandler';
import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Grid, Card, CardContent, Typography, Button, Box, Paper } from '@mui/material';

const LandingPage = () => {
  errorHandler.logDebug('LandingPage', 'LandingPage rendered');
  return (
    <Container maxWidth="lg">
      <Box sx={{ my: 4 }}>
        <Typography variant="h2" align="center" gutterBottom>
          The Salon Management System
        </Typography>
        <Typography variant="subtitle1" align="center" gutterBottom>
          Manage your salon with ease – from finances to staff, holidays to performance reviews, and so much more.
        </Typography>
      </Box>

      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <CardContent sx={{ flexGrow: 1 }}>
              <Typography variant="h5" gutterBottom>Login</Typography>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                Access your account to manage your salon.
              </Typography>
              <Button component={Link} to="/login" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
                Login
              </Button>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <CardContent sx={{ flexGrow: 1 }}>
              <Typography variant="h5" gutterBottom>Register</Typography>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                Create an account to get started.
              </Typography>
              <Button component={Link} to="/register" variant="contained" color="secondary" fullWidth sx={{ mt: 2 }}>
                Register
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Box sx={{ mt: 4, textAlign: 'center' }}>
        <Link to="/password-recovery" style={{ textDecoration: 'none' }}>
          <Typography variant="body2" color="textSecondary">
            Forgot your password?
          </Typography>
        </Link>
      </Box>

      <Paper elevation={3} sx={{ mt: 4, p: 3 }}>
        <Typography variant="h5" gutterBottom>
          About Our Salon Management System
        </Typography>
        <Typography variant="body1">
         Transform your salon into a well-oiled machine with our all-in-one salon management system. Effortlessly handle everything from holiday scheduling and supplier management to employee tracking and financial reporting, all in one user-friendly platform. Designed to simplify your operations, our system frees you to focus on what truly matters—delivering exceptional experiences to your clients. Choose from flexible payment options, including discounted annual plans, and start running your salon smarter, not harder. 
        </Typography>
      </Paper>
    </Container>
  );
};

export default LandingPage;