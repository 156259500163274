import React, { useState, useEffect } from 'react';
import api from './axiosConfig';
import moment from 'moment';
import { 
  Box, 
  Grid, 
  Paper, 
  Typography, 
  CircularProgress, 
  Alert, 
  Button, 
  Snackbar, 
  Tabs, 
  Tab,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow 
} from '@mui/material';
import KPIBox from './KPIBox';

const UserDashboard = () => {
  const [dashboardData, setDashboardData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    startDate: '',
    endDate: '',
    numberOfDaysHoliday: '',
    notes: ''
  });
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [activeTab, setActiveTab] = useState(0);
  const [tabLoading, setTabLoading] = useState(false);

  useEffect(() => {
    fetchDashboardData();
  }, []);

  const fetchDashboardData = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.get('/dashboard/user/dashboard');
      setDashboardData(response.data);
    } catch (err) {
      setError(`Failed to load dashboard data: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));

    if ((name === 'startDate' || name === 'endDate') && formData.startDate && formData.endDate) {
      const start = moment(formData.startDate);
      const end = moment(formData.endDate);
      const numberOfDays = end.diff(start, 'days') + 1;
      setFormData((prev) => ({ ...prev, numberOfDaysHoliday: numberOfDays > 0 ? numberOfDays : '' }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await api.post('/dashboard/user/submit-holiday', formData);
      showSnackbar('Holiday request submitted successfully!', 'success');
      resetForm();
      fetchDashboardData();
    } catch (err) {
      showSnackbar(`Error submitting holiday request: ${err.message}`, 'error');
    }
  };

  const resetForm = () => {
    setFormData({
      startDate: '',
      endDate: '',
      numberOfDaysHoliday: '',
      notes: ''
    });
  };

  const showSnackbar = (message, severity) => {
    setSnackbar({ open: true, message, severity });
  };

  const handleCloseSnackbar = (_, reason) => {
    if (reason === 'clickaway') return;
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  const handleTabChange = (_, newValue) => {
    setActiveTab(newValue);
    setTabLoading(true);
    setTimeout(() => setTabLoading(false), 300); // Simulating tab content loading
  };

  const renderHolidayRequests = () => {
  if (!Array.isArray(dashboardData.holidayRequests)) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Typography>No holiday requests available</Typography>
      </Box>
    );
  }

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Start Date</TableCell>
            <TableCell>End Date</TableCell>
            <TableCell>Days</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Notes</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dashboardData.holidayRequests.map((request, index) => (
            <TableRow key={index}>
              <TableCell>{request.start_date}</TableCell>
              <TableCell>{request.end_date}</TableCell>
              <TableCell>{request.number_of_days_holiday}</TableCell>
              <TableCell>{request.status}</TableCell>
              <TableCell>{request.notes}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

  const renderHolidayForm = () => (
    <Paper sx={{ p: 2, mt: 2 }}>
      <Typography variant="h6" gutterBottom>
        Submit Holiday Request
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Start Date"
              type="date"
              name="startDate"
              value={formData.startDate}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="End Date"
              type="date"
              name="endDate"
              value={formData.endDate}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Number of Days"
              type="number"
              name="numberOfDaysHoliday"
              value={formData.numberOfDaysHoliday}
              InputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Notes"
              multiline
              rows={4}
              name="notes"
              value={formData.notes}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary">
              Submit Request
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 3 }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <Box maxWidth="lg" sx={{ mt: 4, mb: 4, width: '100%' }}>
        <Typography variant="h4" gutterBottom>
          User Dashboard
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <KPIBox title="Holiday Balance" value={`${dashboardData.holidayBalance} days`} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <KPIBox title="Total Days" value={`${dashboardData.totalDays} days`} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <KPIBox title="Taken Days" value={`${dashboardData.takenDays} days`} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <KPIBox title="Pending Days" value={`${dashboardData.pendingDays} days`} />
          </Grid>
        </Grid>
        <Tabs 
          value={activeTab} 
          onChange={handleTabChange} 
          sx={{ mb: 2, mt: 2 }}
          aria-label="Dashboard Tabs"
        >
          <Tab label="Holiday Requests" />
          <Tab label="Submit Request" />
        </Tabs>
        {tabLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <CircularProgress />
          </Box>
        ) : activeTab === 0 ? (
          renderHolidayRequests()
        ) : (
          renderHolidayForm()
        )}
      </Box>
      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={handleCloseSnackbar}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity={snackbar.severity} 
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default UserDashboard;
