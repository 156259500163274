import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import {
    Box,
    Container,
    Typography,
    TextField,
    Button,
    Checkbox,
    FormControlLabel,
    Select,
    MenuItem,
    Paper,
    Grid,
    FormControl,
    InputLabel,
    Alert,
    Divider,
    CircularProgress
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import BusinessIcon from '@mui/icons-material/Business';
import api from './axiosConfig';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const Register = () => {
    const [formData, setFormData] = useState({
        name: '',
        business_name: '',
        business_address_line1: '',
        business_address_line2: '',
        business_address_line3: '',
        postcode: '',
        phone_number: '',
        is_vat_registered: false,
        vat_scheme: '',
        vat_number: '',
        holiday_year_start_month: 1,
        holiday_year_start_day: 1,
        total_holiday_days: 28,
        username: '',
        email: '',
        password: '',
        plan: 'monthly'
    });

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const vatSchemes = [
        { value: 'STANDARD_RATE', label: 'Standard Rate (20%)' },
        { value: 'FLAT_RATE', label: 'Flat Rate Scheme' },
        { value: 'FIRST_YEAR_FLAT_RATE', label: 'First Year Flat Rate' }
    ];

    const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    
    // Convert number inputs to integers
    if (name === 'holiday_year_start_month' || 
        name === 'holiday_year_start_day' || 
        name === 'total_holiday_days') {
        const numValue = parseInt(value);
        // Validate day input
        if (name === 'holiday_year_start_day' && (numValue < 1 || numValue > 31)) {
            return; // Don't update if invalid day
        }
        setFormData(prevData => ({
            ...prevData,
            [name]: numValue
        }));
        return;
    }
    
    // Handle other inputs as before
    setFormData(prevData => ({
        ...prevData,
        [name]: type === 'checkbox' ? checked : value,
        ...(name === 'is_vat_registered' && !checked ? {
            vat_scheme: '',
            vat_number: ''
        } : {})
    }));
};

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError('');
        setLoading(true);

        try {
            const response = await api.post('/register', {
                ...formData,
                product_id: formData.plan === 'monthly' ? 'prod_QOJqIqcLBuKIbA' : 'prod_QOJpU2Tw66J1Dp'
            });

            if (response.status === 200 || response.status === 201) {
                const stripe = await stripePromise;
                const result = await stripe.redirectToCheckout({
                    sessionId: response.data.sessionId
                });

                if (result.error) {
                    setError(result.error.message);
                }
            } else {
                setError('Unexpected response from server');
            }
        } catch (err) {
            console.error('Registration error:', err);
            setError(err.response?.data?.error || 'An error occurred during registration');
        } finally {
            setLoading(false);
        }
    };
    const months = [
    { value: 1, label: 'January' },
    { value: 2, label: 'February' },
    { value: 3, label: 'March' },
    { value: 4, label: 'April' },
    { value: 5, label: 'May' },
    { value: 6, label: 'June' },
    { value: 7, label: 'July' },
    { value: 8, label: 'August' },
    { value: 9, label: 'September' },
    { value: 10, label: 'October' },
    { value: 11, label: 'November' },
    { value: 12, label: 'December' }
];

    return (
        <Container component="main" maxWidth="md">
            <Paper elevation={3} sx={{ p: 4, mt: 8, mb: 4 }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        mb: 4
                    }}
                >
                    <BusinessIcon sx={{ fontSize: 40, color: 'primary.main', mb: 2 }} />
                    <Typography component="h1" variant="h4">
                        Salon Management System
                    </Typography>
                    <Typography variant="subtitle1" sx={{ mt: 1, color: 'text.secondary' }}>
                        Register your business
                    </Typography>
                </Box>

                {error && (
                    <Alert severity="error" sx={{ mb: 3 }}>
                        {error}
                    </Alert>
                )}

                <form onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        {/* Personal Information Section */}
                        <Grid item xs={12}>
                            <Typography variant="h6" sx={{ mb: 2 }}>
                                Personal Information
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        name="name"
                                        label="Full Name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        name="username"
                                        label="Username"
                                        value={formData.username}
                                        onChange={handleChange}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        name="email"
                                        label="Email Address"
                                        type="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        name="password"
                                        label="Password"
                                        type="password"
                                        value={formData.password}
                                        onChange={handleChange}
                                        required
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Divider sx={{ my: 2 }} />
                        </Grid>

                        {/* Business Information Section */}
                        <Grid item xs={12}>
                            <Typography variant="h6" sx={{ mb: 2 }}>
                                Business Information
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        name="business_name"
                                        label="Business Name"
                                        value={formData.business_name}
                                        onChange={handleChange}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        name="business_address_line1"
                                        label="Address Line 1"
                                        value={formData.business_address_line1}
                                        onChange={handleChange}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        name="business_address_line2"
                                        label="Address Line 2"
                                        value={formData.business_address_line2}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        name="business_address_line3"
                                        label="Address Line 3"
                                        value={formData.business_address_line3}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        name="postcode"
                                        label="Postcode"
                                        value={formData.postcode}
                                        onChange={handleChange}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        name="phone_number"
                                        label="Phone Number"
                                        value={formData.phone_number}
                                        onChange={handleChange}
                                        required
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Divider sx={{ my: 2 }} />
                        </Grid>
                        <Grid item xs={12}>
    <Typography variant="h6" sx={{ mb: 2 }}>
        Holiday Year Settings
    </Typography>
    <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
                <InputLabel>Holiday Year Start Month</InputLabel>
                <Select
                    name="holiday_year_start_month"
                    value={formData.holiday_year_start_month}
                    onChange={handleChange}
                    label="Holiday Year Start Month"
                >
                    {months.map(month => (
                        <MenuItem key={month.value} value={month.value}>
                            {month.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
            <TextField
                fullWidth
                type="number"
                name="holiday_year_start_day"
                label="Start Day of Month"
                value={formData.holiday_year_start_day}
                onChange={handleChange}
                inputProps={{ min: 1, max: 31 }}
                helperText="Enter a day (1-31)"
            />
        </Grid>
        <Grid item xs={12} sm={4}>
            <TextField
                fullWidth
                type="number"
                name="total_holiday_days"
                label="Total Holiday Days"
                value={formData.total_holiday_days}
                onChange={handleChange}
                inputProps={{ min: 0 }}
                helperText="Default: 28 days"
            />
        </Grid>
    </Grid>
</Grid>

<Grid item xs={12}>
    <Divider sx={{ my: 2 }} />
</Grid>

                        {/* VAT Section */}
                        <Grid item xs={12}>
                            <Typography variant="h6" sx={{ mb: 2 }}>
                                VAT Information
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name="is_vat_registered"
                                                checked={formData.is_vat_registered}
                                                onChange={handleChange}
                                                color="primary"
                                            />
                                        }
                                        label="Business is VAT Registered"
                                    />
                                </Grid>
                                {formData.is_vat_registered && (
                                    <>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                fullWidth
                                                name="vat_number"
                                                label="VAT Number"
                                                value={formData.vat_number}
                                                onChange={handleChange}
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <FormControl fullWidth required>
                                                <InputLabel>VAT Scheme</InputLabel>
                                                <Select
                                                    name="vat_scheme"
                                                    value={formData.vat_scheme}
                                                    onChange={handleChange}
                                                    label="VAT Scheme"
                                                >
                                                    {vatSchemes.map(scheme => (
                                                        <MenuItem key={scheme.value} value={scheme.value}>
                                                            {scheme.label}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Divider sx={{ my: 2 }} />
                        </Grid>

                        {/* Subscription Section */}
                        <Grid item xs={12}>
                            <Typography variant="h6" sx={{ mb: 2 }}>
                                Subscription Plan
                            </Typography>
                            <FormControl fullWidth required>
                                <InputLabel>Select Plan</InputLabel>
                                <Select
                                    name="plan"
                                    value={formData.plan}
                                    onChange={handleChange}
                                    label="Select Plan"
                                >
                                    <MenuItem value="monthly">Monthly Plan (£59.99/month)</MenuItem>
                                    <MenuItem value="yearly">Yearly Plan (£500.00/year)</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        {/* Submit Button */}
                        <Grid item xs={12}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                size="large"
                                disabled={loading}
                                sx={{ mt: 2 }}
                            >
                                {loading ? (
                                    <CircularProgress size={24} color="inherit" />
                                ) : (
                                    'Register and Subscribe'
                                )}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </Container>
    );
};

export default Register;