import React, { useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  Button,
  Tabs,
  Tab,
  Alert,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';
import Papa from 'papaparse';
import api from '../axiosConfig';

const SupplierImport = ({ setMessage, setMessageType }) => {
  const [activeTab, setActiveTab] = useState('INSTRUCTIONS');
  const [validationErrors, setValidationErrors] = useState([]);
  const [importResults, setImportResults] = useState(null);

  // Constants based on DB structure
  const VALID_SUPPLIER_TYPES = ['Supplies', 'Services', 'Products'];

  // Required fields based on DB NOT NULL constraints
  const REQUIRED_FIELDS = [
    'supplier_name',
    'type',
    'address',
    'contact_number'
  ];

  // Validation functions
  const validateEmail = (email) => {
    if (!email) return true; // Email is optional
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const validatePhone = (phone) => {
    const re = /^[+]?[\d\s()-]{10,}$/;
    return phone && re.test(phone.replace(/\s/g, ''));
  };

  const validateVATNumber = (vatNumber) => {
    if (!vatNumber) return true; // VAT number is optional
    const re = /^GB\d{9}$/;
    return re.test(vatNumber.replace(/\s/g, ''));
  };

  const validateSupplierData = (data) => {
    const errors = [];

    // Required fields check based on DB NOT NULL
    REQUIRED_FIELDS.forEach(field => {
      if (!data[field]) {
        errors.push(`Missing required field: ${field}`);
      }
    });

    // Type validation
    if (data.type && !VALID_SUPPLIER_TYPES.includes(data.type)) {
      errors.push(`Invalid supplier type. Must be one of: ${VALID_SUPPLIER_TYPES.join(', ')}`);
    }

    // Field length validations (based on varchar lengths)
    if (data.supplier_name && data.supplier_name.length > 150) {
      errors.push('Supplier name must be 150 characters or less');
    }
    if (data.contact_number && data.contact_number.length > 20) {
      errors.push('Contact number must be 20 characters or less');
    }
    if (data.email && data.email.length > 150) {
      errors.push('Email must be 150 characters or less');
    }
    if (data.vat_number && data.vat_number.length > 50) {
      errors.push('VAT number must be 50 characters or less');
    }
    if (data.account_number && data.account_number.length > 50) {
      errors.push('Account number must be 50 characters or less');
    }

    // Format validations for provided fields
    if (data.email && !validateEmail(data.email)) {
      errors.push('Invalid email format');
    }
    if (data.contact_number && !validatePhone(data.contact_number)) {
      errors.push('Invalid phone number format');
    }
    if (data.vat_number && !validateVATNumber(data.vat_number)) {
      errors.push('Invalid VAT number format (should be GB followed by 9 digits)');
    }
    if (data.vat_applicable !== undefined && ![0, 1, '0', '1', true, false].includes(data.vat_applicable)) {
      errors.push('VAT applicable must be 0 or 1');
    }

    return errors;
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleDownloadTemplate = () => {
    const templateContent = 'type,supplier_name,address,contact_number,email,vat_number,account_number,vat_applicable\n' +
      'Services,Example Supplier Ltd,"123 Business St, City, AB12 3CD",01234567890,contact@example.com,GB123456789,ACC123,1\n' +
      'Products,Another Supplier,"456 Trading Park, Town, XY34 5ZZ",07123456789,,,ACC456,0';  // Example with optional fields empty

    const blob = new Blob([templateContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'supplier_import_template.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    // Reset previous results and errors
    setValidationErrors([]);
    setImportResults(null);

    Papa.parse(file, {
      header: true,
      skipEmptyRows: true,
      complete: async (results) => {
        const allErrors = [];
        const validData = [];

        // Validate each row
        results.data.forEach((row, index) => {
          if (Object.values(row).every(value => !value)) {
            return;
          }

          const rowErrors = validateSupplierData(row);
          if (rowErrors.length > 0) {
            allErrors.push(`Row ${index + 1}: ${rowErrors.join(', ')}`);
          } else {
            validData.push(row);
          }
        });

        if (allErrors.length > 0) {
          setValidationErrors(allErrors);
          setMessage('Validation errors found. Please check the errors below and try again.');
          setMessageType('error');
          return;
        }

        try {
          const response = await api.post('/suppliers/import', validData);
          const results = response.data;
          setImportResults(results);

          if (results.successful_imports === 0) {
            setMessageType('error');
            if (results.errors && results.errors.length > 0) {
              setValidationErrors(results.errors);
              setMessage('Import failed. Please check the errors below.');
            } else {
              setMessage('Import failed. No suppliers were imported.');
            }
          } else {
            if (results.failed_imports > 0) {
              setMessageType('warning');
              setMessage(`Imported ${results.successful_imports} suppliers with ${results.failed_imports} failures.`);
              if (results.errors) {
                setValidationErrors(results.errors);
              }
            } else {
              setMessageType('success');
              setMessage(`Successfully imported ${results.successful_imports} suppliers!`);
            }
          }

          // Clear file input
          event.target.value = '';

        } catch (error) {
          console.error('Error uploading file:', error);
          setMessageType('error');
          if (error.response?.data?.errors) {
            setValidationErrors(error.response.data.errors);
            setMessage('Import failed. Please check the errors below.');
          } else {
            setMessage('Error importing suppliers. Please try again.');
          }
        }
      },
      error: (error) => {
        console.error('Error parsing CSV:', error);
        setMessage('Error reading CSV file. Please check the format and try again.');
        setMessageType('error');
      }
    });
  };

  return (
    <Paper elevation={3} sx={{ p: 3, maxWidth: 1200, margin: '0 auto' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h5">Supplier Import</Typography>
      </Box>

      <Box sx={{ mb: 3 }}>
        <Tabs 
          value={activeTab} 
          onChange={handleTabChange}
          sx={{
            borderBottom: 1,
            borderColor: 'divider'
          }}
        >
          <Tab label="Import Instructions" value="INSTRUCTIONS" />
          <Tab label="Import" value="IMPORT" />
        </Tabs>
      </Box>

      {activeTab === 'INSTRUCTIONS' && (
        <Box>
          <Typography variant="h6" gutterBottom>Before You Import</Typography>
          <Alert severity="info" sx={{ mb: 3 }}>
            <Typography variant="subtitle2">Required Fields:</Typography>
            <ul>
              <li>Supplier Name (max 150 characters)</li>
              <li>Type (Supplies, Services, or Products)</li>
              <li>Address</li>
              <li>Contact Number (max 20 characters)</li>
            </ul>
          </Alert>

          <Alert severity="warning" sx={{ mb: 3 }}>
            <Typography variant="subtitle2">Optional Fields:</Typography>
            <ul>
              <li>Email (max 150 characters)</li>
              <li>VAT Number (GB format)</li>
              <li>Account Number (max 50 characters)</li>
              <li>VAT Applicable (0 or 1)</li>
            </ul>
          </Alert>

          <Alert severity="info" sx={{ mb: 3 }}>
            <Typography variant="subtitle2">Valid Supplier Types:</Typography>
            <ul>
              {VALID_SUPPLIER_TYPES.map(type => (
                <li key={type}>{type}</li>
              ))}
            </ul>
          </Alert>
        </Box>
      )}

      {activeTab === 'IMPORT' && (
        <Box>
          <Box>
            <Typography variant="subtitle1" gutterBottom>Import Instructions</Typography>
            <Alert severity="info" sx={{ mb: 3 }}>
              <Typography variant="body2">
                1. Download the template below
                <br />
                2. Fill in your supplier data following the format requirements
                <br />
                3. Save and upload your completed file
                <br />
                Required fields must be completed.
              </Typography>
            </Alert>

            {validationErrors.length > 0 && (
              <Alert severity="error" sx={{ mb: 3 }}>
                <Typography variant="subtitle2">Validation Errors:</Typography>
                <ul>
                  {validationErrors.map((error, index) => (
                    <li key={index}>{error}</li>
                  ))}
                </ul>
              </Alert>
            )}

            {importResults && (
              <Alert 
                severity={importResults.successful_imports > 0 ? 
                  (importResults.failed_imports > 0 ? 'warning' : 'success') : 
                  'error'
                } 
                sx={{ mb: 3 }}
              >
                <Typography variant="subtitle2">Import Results:</Typography>
                <ul>
                  <li>Successfully imported: {importResults.successful_imports}</li>
                  {importResults.failed_imports > 0 && (
                    <li>Failed imports: {importResults.failed_imports}</li>
                  )}
                </ul>
              </Alert>
            )}

            <Button
              startIcon={<DownloadIcon />}
              variant="outlined"
              onClick={handleDownloadTemplate}
              sx={{ mt: 2, mb: 4 }}
            >
              DOWNLOAD TEMPLATE
            </Button>
          </Box>

          <Box>
            <Typography variant="subtitle1" gutterBottom>Import Data</Typography>
            <input
              type="file"
              accept=".csv"
              onChange={handleFileUpload}
              style={{ display: 'none' }}
              id="csv-file-input"
            />
            <label htmlFor="csv-file-input">
              <Button
                startIcon={<UploadIcon />}
                variant="contained"
                component="span"
              >
                Choose CSV File
              </Button>
            </label>
          </Box>
        </Box>
      )}
    </Paper>
  );
};

export default SupplierImport;