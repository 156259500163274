import errorHandler from './utils/errorHandler';
import React, { useState, useEffect, useCallback } from 'react';
import { Navigate } from 'react-router-dom';
import api from './axiosConfig';
import { Box, Paper, Typography, CircularProgress, Alert, Grid, Button, TextField, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { Edit} from '@mui/icons-material';
import { Divider, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Checkbox } from '@mui/material';
import DatePickerComponent from './DatePickerComponent';
import { Bar } from 'react-chartjs-2';
import { useAuth } from './AuthContext';
import dayjs from 'dayjs';
import GaugeChart from 'react-gauge-chart';
import KPIBox from './KPIBox';

import { Chart, LinearScale, CategoryScale, BarController, BarElement } from 'chart.js';
Chart.register(LinearScale, CategoryScale, BarController, BarElement);

const CompanyProfileSection = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [profileData, setProfileData] = useState({
    business_name: '',
    business_address_line1: '',
    business_address_line2: '',
    business_address_line3: '',
    postcode: '',
    phone_number: '',
    is_vat_registered: false,
    vat_number: '',
    vat_scheme: '',
    holiday_year_start_month: 1,
    holiday_year_start_day: 1,
    total_holiday_days: 28
  });
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');

  const vatSchemes = [
    { value: 'STANDARD_RATE', label: 'Standard Rate (20%)' },
    { value: 'FLAT_RATE', label: 'Flat Rate Scheme' },
    { value: 'FIRST_YEAR_FLAT_RATE', label: 'First Year Flat Rate' }
  ];

  const months = [
    { value: 1, label: 'January' },
    { value: 2, label: 'February' },
    { value: 3, label: 'March' },
    { value: 4, label: 'April' },
    { value: 5, label: 'May' },
    { value: 6, label: 'June' },
    { value: 7, label: 'July' },
    { value: 8, label: 'August' },
    { value: 9, label: 'September' },
    { value: 10, label: 'October' },
    { value: 11, label: 'November' },
    { value: 12, label: 'December' }
  ];

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await api.get('/company/profile');
        setProfileData(response.data);
      } catch (error) {
        console.error('Error fetching company profile:', error);
        setError('Failed to load company profile');
      }
    };
    fetchProfile();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    
    if (name === 'holiday_year_start_month' || 
        name === 'holiday_year_start_day' || 
        name === 'total_holiday_days') {
      const numValue = parseInt(value);
      if (name === 'holiday_year_start_day' && (numValue < 1 || numValue > 31)) {
        return;
      }
      setProfileData(prev => ({
        ...prev,
        [name]: numValue
      }));
      return;
    }

    setProfileData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
      ...(name === 'is_vat_registered' && !checked ? {
        vat_scheme: '',
        vat_number: ''
      } : {})
    }));
  };

  const handleSubmit = async () => {
    try {
      await api.put('/company/profile', {
        ...profileData,
        password: confirmPassword
      });
      setIsEditing(false);
      setConfirmPassword('');
      setError('');
    } catch (error) {
      console.error('Error updating profile:', error);
      setError('Failed to update profile');
    }
  };

  return (
    <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h5">Company Profile</Typography>
        <Button
          variant="contained"
          onClick={() => setIsEditing(true)}
          startIcon={<Edit/>}
        >
          Edit Profile
        </Button>
      </Box>

      {/* Display Mode */}
      {!isEditing && (
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" color="text.secondary">Business Information</Typography>
            <Typography><strong>Business Name:</strong> {profileData.business_name}</Typography>
            <Typography><strong>Phone:</strong> {profileData.phone_number}</Typography>
            <Typography component="div" sx={{ mt: 1 }}>
              <strong>Address:</strong><br />
              {profileData.business_address_line1}<br />
              {profileData.business_address_line2 && <>{profileData.business_address_line2}<br /></>}
              {profileData.business_address_line3 && <>{profileData.business_address_line3}<br /></>}
              {profileData.postcode}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" color="text.secondary">VAT Information</Typography>
            <Typography><strong>VAT Registered:</strong> {profileData.is_vat_registered ? 'Yes' : 'No'}</Typography>
            {profileData.is_vat_registered && (
              <>
                <Typography><strong>VAT Number:</strong> {profileData.vat_number}</Typography>
                <Typography><strong>VAT Scheme:</strong> {profileData.vat_scheme}</Typography>
              </>
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" color="text.secondary">Holiday Year Settings</Typography>
            <Typography>
              <strong>Holiday Year Starts:</strong> {months.find(m => m.value === profileData.holiday_year_start_month)?.label} {profileData.holiday_year_start_day}
            </Typography>
            <Typography><strong>Total Holiday Days:</strong> {profileData.total_holiday_days}</Typography>
          </Grid>
        </Grid>
      )}

      {/* Edit Dialog */}
      <Dialog 
        open={isEditing} 
        onClose={() => setIsEditing(false)} 
        maxWidth="md" 
        fullWidth
      >
        <DialogTitle>
          Edit Company Profile
          {error && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          )}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3} sx={{ mt: 1 }}>
            {/* Business Information Section */}
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Business Information
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Business Name"
                    name="business_name"
                    value={profileData.business_name}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Address Line 1"
                    name="business_address_line1"
                    value={profileData.business_address_line1}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Address Line 2"
                    name="business_address_line2"
                    value={profileData.business_address_line2}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Address Line 3"
                    name="business_address_line3"
                    value={profileData.business_address_line3}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Postcode"
                    name="postcode"
                    value={profileData.postcode}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Phone Number"
                    name="phone_number"
                    value={profileData.phone_number}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider sx={{ my: 2 }} />
            </Grid>

            {/* Holiday Year Settings Section */}
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Holiday Year Settings
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <InputLabel>Holiday Year Start Month</InputLabel>
                    <Select
                      name="holiday_year_start_month"
                      value={profileData.holiday_year_start_month}
                      onChange={handleChange}
                      label="Holiday Year Start Month"
                    >
                      {months.map(month => (
                        <MenuItem key={month.value} value={month.value}>
                          {month.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    type="number"
                    name="holiday_year_start_day"
                    label="Start Day of Month"
                    value={profileData.holiday_year_start_day}
                    onChange={handleChange}
                    inputProps={{ min: 1, max: 31 }}
                    helperText="Enter a day (1-31)"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    type="number"
                    name="total_holiday_days"
                    label="Total Holiday Days"
                    value={profileData.total_holiday_days}
                    onChange={handleChange}
                    inputProps={{ min: 0 }}
                    helperText="Default: 28 days"
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider sx={{ my: 2 }} />
            </Grid>

            {/* VAT Information Section */}
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                VAT Information
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="is_vat_registered"
                        checked={profileData.is_vat_registered}
                        onChange={handleChange}
                      />
                    }
                    label="Business is VAT Registered"
                  />
                </Grid>
                {profileData.is_vat_registered && (
                  <>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="VAT Number"
                        name="vat_number"
                        value={profileData.vat_number}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <InputLabel>VAT Scheme</InputLabel>
                        <Select
                          name="vat_scheme"
                          value={profileData.vat_scheme}
                          onChange={handleChange}
                          label="VAT Scheme"
                        >
                          {vatSchemes.map(scheme => (
                            <MenuItem key={scheme.value} value={scheme.value}>
                              {scheme.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider sx={{ my: 2 }} />
            </Grid>

            {/* Password Confirmation */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Confirm Password"
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                helperText="Please enter your password to save changes"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsEditing(false)}>Cancel</Button>
          <Button 
            onClick={handleSubmit} 
            variant="contained"
            disabled={!confirmPassword}
          >
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

const OwnerDashboard = () => {
  const { isAuthenticated, user } = useAuth();
  const [dashboardData, setDashboardData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [startDate, setStartDate] = useState(dayjs().subtract(1, 'year').startOf('day'));
  const [endDate, setEndDate] = useState(dayjs().endOf('day'));

  const fetchDashboardData = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const [kpiResponse, revenueResponse, requestsResponse] = await Promise.all([
        api.get('/dashboard/kpi_data', { 
          params: {
            start_date: startDate.format('YYYY-MM-DD'), 
            end_date: endDate.format('YYYY-MM-DD') 
          }
        }),
        api.get('/dashboard/revenue_data', { 
          params: {
            start_date: startDate.format('YYYY-MM-DD'), 
            end_date: endDate.format('YYYY-MM-DD')
          }
        }),
        api.get('/dashboard/pending_requests')
      ]);

      errorHandler.logDebug('OwnerDashboard', 'Raw KPI Response:', kpiResponse.data);
      errorHandler.logDebug('OwnerDashboard', 'Raw Revenue Response:', revenueResponse.data);
      errorHandler.logDebug('OwnerDashboard', 'Raw Pending Requests Response:', requestsResponse.data);

      if (kpiResponse.data.error || revenueResponse.data.error || requestsResponse.data.error) {
        throw new Error('Error in one of the API responses');
      }

      setDashboardData({
        kpiData: kpiResponse.data,
        revenueData: revenueResponse.data,
        pendingRequests: requestsResponse.data.pending_requests
      });
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
      setError('Failed to load dashboard data. Please check your network connection and try again.');
    } finally {
      setLoading(false);
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (isAuthenticated && user?.role === 'ACCOUNT_OWNER') {
      fetchDashboardData();
    }
  }, [isAuthenticated, user, fetchDashboardData]);

  if (!isAuthenticated || user?.role !== 'ACCOUNT_OWNER') {
    return <Navigate to="/unauthorized" replace />;
  }

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
        <Typography variant="h6" sx={{ ml: 2 }}>Loading dashboard data...</Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 3 }}>
        <Alert severity="error">{error}</Alert>
        <Button onClick={fetchDashboardData} sx={{ mt: 2 }}>Retry</Button>
      </Box>
    );
  }

  if (!dashboardData) {
    return (
      <Box sx={{ p: 3 }}>
        <Alert severity="info">No dashboard data available.</Alert>
        <Button onClick={fetchDashboardData} sx={{ mt: 2 }}>Refresh Data</Button>
      </Box>
    );
  }

  errorHandler.logDebug('OwnerDashboard', 'Dashboard Data:', dashboardData);

  const { kpiData, revenueData, pendingRequests } = dashboardData;

  const barChartData = {
    labels: revenueData.monthlyData?.map(item => item.month) || [],
    datasets: [{
      label: 'Monthly Revenue',
      data: revenueData.monthlyData?.map(item => item.total) || [],
      backgroundColor: 'rgba(75,192,192,1)',
    }]
  };

  const barOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Revenue (£)'
        }
      }
    },
    plugins: {
      legend: {
        display: false
      }
    }
  };

  return (
    <Box className="dashboard-container" sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Account Owner Dashboard
      </Typography>
      <CompanyProfileSection />
      <DatePickerComponent startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} />
      <Button onClick={fetchDashboardData} sx={{ mt: 2, mb: 2 }}>Refresh Data</Button>
      
      <Box sx={{ display: 'flex', justifyContent: 'space-around', my: 4 }}>
  {/* Wages to Turnover Ratio */}
  <Box sx={{ textAlign: 'center' }}>
    <Typography variant="subtitle1" gutterBottom>Wages to Turnover</Typography>
    <GaugeChart
      id="wages-gauge"
      nrOfLevels={20}
      colors={["#00FF00", "#FFA500", "#FF0000"]}
      arcWidth={0.3}
      percent={Math.min((kpiData.wages?.value || 0) / (revenueData.annualTotal || 1), 1)}
      textColor="#000000"
      formatTextValue={(value) => {
        const percent = ((kpiData.wages?.value || 0) / (revenueData.annualTotal || 1) * 100);
        return `${percent.toFixed(1)}%`;
      }}
    />
    <Typography variant="caption" color="textSecondary">
      Target: Below 35%
    </Typography>
  </Box>

  {/* Retail to Service Ratio */}
  <Box sx={{ textAlign: 'center' }}>
    <Typography variant="subtitle1" gutterBottom>Retail to Service Sales</Typography>
    <GaugeChart
      id="retail-gauge"
      nrOfLevels={20}
      colors={["#00FF00", "#FFA500", "#FF0000"]}
      arcWidth={0.3}
      percent={Math.min((kpiData.retailSales?.value || 0) / (kpiData.serviceSales?.value || 1), 1)}
      textColor="#000000"
      formatTextValue={(value) => {
        const percent = ((kpiData.retailSales?.value || 0) / (kpiData.serviceSales?.value || 1) * 100);
        return `${percent.toFixed(1)}%`;
      }}
    />
    <Typography variant="caption" color="textSecondary">
      Target: Above 20%
    </Typography>
  </Box>

  {/* Operating Profit Margin */}
  <Box sx={{ textAlign: 'center' }}>
    <Typography variant="subtitle1" gutterBottom>Operating Profit Margin</Typography>
    <GaugeChart
      id="profit-gauge"
      nrOfLevels={20}
      colors={["#00FF00", "#FFA500", "#FF0000"]}
      arcWidth={0.3}
      percent={Math.min(
        ((revenueData.annualTotal || 0) - (kpiData.totalCosts?.value || 0)) / (revenueData.annualTotal || 1),
        1
      )}
      textColor="#000000"
      formatTextValue={(value) => {
        const profit = (revenueData.annualTotal || 0) - (kpiData.totalCosts?.value || 0);
        const percent = (profit / (revenueData.annualTotal || 1) * 100);
        return `${percent.toFixed(1)}%`;
      }}
    />
    <Typography variant="caption" color="textSecondary">
      Target: Above 15%
    </Typography>
  </Box>
</Box>

      <Paper elevation={3} sx={{ p: 2, mb: 4, height: 400 }}>
        <Typography variant="h6" gutterBottom>Monthly Net Turnover</Typography>
        <Bar data={barChartData} options={barOptions} />
      </Paper>

      <Grid container spacing={3}>
        {Object.entries(kpiData).map(([key, data]) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={key}>
            <KPIBox 
              title={key}
              value={data.value}
              growth={data.growth}
              prefix={['expenditure', 'vatCharged', 'vatPaid', 'vatDifference', 'avgTicket'].includes(key) ? '£' : ''}
              isWholeNumber={['tickets', 'reminders'].includes(key)}
            />
          </Grid>
        ))}
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <KPIBox 
            title="Pending Holiday Requests" 
            value={pendingRequests} 
            growth={0}
            isWholeNumber={true}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default OwnerDashboard;