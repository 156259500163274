import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './AuthContext';
import Layout from './Layout';
import ProtectedRoute from './ProtectedRoute';
import ErrorBoundary from './ErrorBoundary';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// Public routes
import LandingPage from './LandingPage';
import Login from './Login';
import Register from './Register';
import PasswordRecovery from './PasswordRecovery';
import PasswordReset from './PasswordReset';
import RegistrationSuccess from './RegistrationSuccess';
import EmploymentApplicationForm from './EmploymentApplicationForm';
import Unauthorized from './Unauthorized';

// Protected routes
import Dashboard from './Dashboard';
import AdminDashboard from './AdminDashboard';
import OwnerDashboard from './OwnerDashboard';
import ManagerDashboard from './ManagerDashboard';
import UserDashboard from './UserDashboard';
import EmployeeCard from './EmployeeCard';
import ManageApplications from './ManageApplications';
import EmployeeReviews from './EmployeeReviews';
import HolidayCalendar from './HolidayCalendar';
import SubmitHolidayRequest from './SubmitHolidayRequest';
import SubmitAbsence from './SubmitAbsence';
import HolidayApproval from './HolidayApproval';
import SupplierCard from './SupplierCard';
import IncomeCard from './IncomeCard';
import Notes from './Notes';
import PersonalInfo from './PersonalInfo';
import SubmitTraining from './SubmitTraining';
import Disciplinary from './Disciplinary';
import RentalDashboard from './RentalDashboard';
import RentalLayout from './RentalLayout';
import DocumentApproval from './DocumentApproval';
import Reminders from './Reminders';
import MessagesDashboard from './Messages_Dashboard';
import SubmitEmployeeReview from './SubmitEmployeeReview';
import TaxReport from './TaxReport';
import CashflowReport from './CashflowReport';
import ProfitLossReport from './ProfitLossReport';
import ReportsDashboard from './ReportsDashboard';
import { 
  IncomeImport, 
  ExpenditureImport, 
  SupplierImport,  
  EmployeeImport,  
  ComingSoon 
} from './imports';

function App() {
  return (
    <Router>
      <ErrorBoundary>
        <AuthProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Routes>
              {/* Public routes */}
              <Route path="/" element={<LandingPage />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/password-recovery" element={<PasswordRecovery />} />
              <Route path="/password-reset/:token" element={<PasswordReset />} />
              <Route path="/registration-success" element={<RegistrationSuccess />} />
              <Route path="/unauthorized" element={<Unauthorized />} />
              <Route path="/apply/:applicationUrlId" element={<EmploymentApplicationForm />} />
              
              {/* Rental User Route - Separate protected section */}
              <Route 
                path="/rental/*" 
                element={
                  <ProtectedRoute allowedRoles={['RENTAL_USER']}>
                    <RentalLayout>
                      <RentalDashboard />
                    </RentalLayout>
                  </ProtectedRoute>
                }
              />

              {/* Protected routes wrapped in Layout */}
              <Route element={<ProtectedRoute><Layout /></ProtectedRoute>}>
                <Route path="/home" element={<Navigate to="/dashboard" replace />} />

                {/* Dashboard routes based on role */}
                <Route path="/dashboard" element={<Dashboard />} />
                <Route
                  path="/admin_dashboard"
                  element={
                    <ProtectedRoute allowedRoles={['ADMIN']}>
                      <AdminDashboard />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/owner_dashboard"
                  element={
                    <ProtectedRoute allowedRoles={['ACCOUNT_OWNER']}>
                      <OwnerDashboard />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/manager_dashboard"
                  element={
                    <ProtectedRoute allowedRoles={['ACCOUNT_OWNER', 'ACCOUNT_MANAGER']}>
                      <ManagerDashboard />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/user_dashboard"
                  element={
                    <ProtectedRoute allowedRoles={['ACCOUNT_OWNER', 'ACCOUNT_MANAGER', 'ACCOUNT_USER']}>
                      <UserDashboard />
                    </ProtectedRoute>
                  }
                />

                {/* Employee Management - Owner and Manager only */}
                <Route
                  path="/employee_card"
                  element={
                    <ProtectedRoute allowedRoles={['ACCOUNT_OWNER', 'ACCOUNT_MANAGER']}>
                      <EmployeeCard />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/employee_reviews"
                  element={
                    <ProtectedRoute allowedRoles={['ACCOUNT_OWNER', 'ACCOUNT_MANAGER']}>
                      <EmployeeReviews />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/submit_employee_review"
                  element={
                    <ProtectedRoute allowedRoles={['ACCOUNT_OWNER', 'ACCOUNT_MANAGER']}>
                      <SubmitEmployeeReview />
                    </ProtectedRoute>
                  }
                />

                {/* Time Management - All roles */}
                <Route
                  path="/holiday_calendar"
                  element={
                    <ProtectedRoute allowedRoles={['ACCOUNT_OWNER', 'ACCOUNT_MANAGER', 'ACCOUNT_USER']}>
                      <HolidayCalendar />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/submit_holiday_request"
                  element={
                    <ProtectedRoute allowedRoles={['ACCOUNT_OWNER', 'ACCOUNT_MANAGER', 'ACCOUNT_USER']}>
                      <SubmitHolidayRequest />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/submit_absence"
                  element={
                    <ProtectedRoute allowedRoles={['ACCOUNT_OWNER', 'ACCOUNT_MANAGER', 'ACCOUNT_USER']}>
                      <SubmitAbsence />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/holiday_approval"
                  element={
                    <ProtectedRoute allowedRoles={['ACCOUNT_OWNER', 'ACCOUNT_MANAGER']}>
                      <HolidayApproval />
                    </ProtectedRoute>
                  }
                />

                {/* Financial and Business Management - Owner only */}
                <Route
                  path="/supplier_card"
                  element={
                    <ProtectedRoute allowedRoles={['ACCOUNT_OWNER']}>
                      <SupplierCard />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/income_card"
                  element={
                    <ProtectedRoute allowedRoles={['ACCOUNT_OWNER']}>
                      <IncomeCard />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/reports/dashboard"
                  element={
                    <ProtectedRoute allowedRoles={['ACCOUNT_OWNER']}>
                      <ReportsDashboard />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/reports/tax"
                  element={
                    <ProtectedRoute allowedRoles={['ACCOUNT_OWNER']}>
                      <TaxReport />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/reports/cashflow"
                  element={
                    <ProtectedRoute allowedRoles={['ACCOUNT_OWNER']}>
                      <CashflowReport />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/reports/profit-loss"
                  element={
                    <ProtectedRoute allowedRoles={['ACCOUNT_OWNER']}>
                      <ProfitLossReport />
                    </ProtectedRoute>
                  }
                />

                {/* Data Management Routes */}
                <Route
                  path="/import/income"
                  element={
                    <ProtectedRoute allowedRoles={['ACCOUNT_OWNER']}>
                      <IncomeImport />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/import/expenditure"
                  element={
                    <ProtectedRoute allowedRoles={['ACCOUNT_OWNER']}>
                      <ExpenditureImport />
                    </ProtectedRoute>
                  }
                />
                <Route 
                  path="/import/suppliers" 
                  element={
                    <ProtectedRoute allowedRoles={['ACCOUNT_OWNER']}>
                      <SupplierImport />
                    </ProtectedRoute>
                  }
                />
                <Route 
                  path="/import/employees" 
                  element={
                    <ProtectedRoute allowedRoles={['ACCOUNT_OWNER']}>
                      <EmployeeImport />
                    </ProtectedRoute>
                  }
                />

                <Route 
                  path="/rental_dashboard" 
                  element={
                    <ProtectedRoute allowedRoles={['ACCOUNT_OWNER', 'ACCOUNT_MANAGER']}>
                      <RentalDashboard />
                    </ProtectedRoute>
                  }
                />
                
                {/* Common Features - All roles */}
                <Route
                  path="/notes"
                  element={
                    <ProtectedRoute allowedRoles={['ACCOUNT_OWNER', 'ACCOUNT_MANAGER', 'ACCOUNT_USER']}>
                      <Notes />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/reminders"
                  element={
                    <ProtectedRoute allowedRoles={['ACCOUNT_OWNER', 'ACCOUNT_MANAGER', 'ACCOUNT_USER']}>
                      <Reminders />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/personal_info"
                  element={
                    <ProtectedRoute allowedRoles={['ACCOUNT_OWNER', 'ACCOUNT_MANAGER', 'ACCOUNT_USER']}>
                      <PersonalInfo />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/messages"
                  element={
                    <ProtectedRoute allowedRoles={['ACCOUNT_OWNER', 'ACCOUNT_MANAGER', 'ACCOUNT_USER']}>
                      <MessagesDashboard />
                    </ProtectedRoute>
                  }
                />

                {/* Training and Development */}
                <Route
                  path="/submit_training"
                  element={
                    <ProtectedRoute allowedRoles={['ACCOUNT_OWNER']}>
                      <SubmitTraining />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/employment_applications"
                  element={
                    <ProtectedRoute allowedRoles={['ACCOUNT_OWNER', 'ACCOUNT_MANAGER']}>
                      <ManageApplications />
                    </ProtectedRoute>
                  }
                />

                {/* Administrative Features */}
                <Route
                  path="/disciplinary"
                  element={
                    <ProtectedRoute allowedRoles={['ACCOUNT_OWNER', 'ACCOUNT_MANAGER']}>
                      <Disciplinary />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/document_approval"
                  element={
                    <ProtectedRoute allowedRoles={['ACCOUNT_OWNER', 'ACCOUNT_MANAGER']}>
                      <DocumentApproval />
                    </ProtectedRoute>
                  }
                />
              </Route>

              {/* Catch-all redirect to landing page */}
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </LocalizationProvider>
        </AuthProvider>
      </ErrorBoundary>
    </Router>
  );
}

export default App;