import React, { useState, useEffect } from 'react';
import api from './axiosConfig';
import { 
  Grid, 
  Paper, 
  Typography, 
  Container, 
  CircularProgress, 
  Alert, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  TextField, 
  Button, 
  Switch, 
  FormControlLabel,
  Box
} from '@mui/material';
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot } from '@mui/lab';
import { SecurityOutlined, Speed, People, ErrorOutline, Assessment } from '@mui/icons-material';
import { useAuth } from './AuthContext';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const AdminDashboard = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dashboardData, setDashboardData] = useState(null);
  const [users, setUsers] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [auditMetrics, setAuditMetrics] = useState(null);
  const [vatRates, setVatRates] = useState({
    standard_rate: '',
    first_year_rate: '',
    following_year_rate: ''
  });
  const [newUser, setNewUser] = useState({
    email: '',
    tempPassword: '',
    role: 'ACCOUNT_OWNER',
    has_free_access: true
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [dashboardResponse, usersResponse, accountsResponse, auditResponse] = await Promise.all([
          api.get('/admin/dashboard'),
          api.get('/admin/users'),
          api.get('/admin/accounts'),
          api.get('/admin/audit-metrics')
        ]);
        
        setDashboardData(dashboardResponse.data);
        setUsers(usersResponse.data);
        setAccounts(accountsResponse.data);
        setVatRates(dashboardResponse.data.vat_rates);
        setAuditMetrics(auditResponse.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching admin data:', error);
        setError('Failed to load admin data. Please try again later.');
        setLoading(false);
      }
    };

    fetchData();
    
    // Refresh audit metrics every 5 minutes
    const interval = setInterval(() => {
      api.get('/admin/audit-metrics')
        .then(response => setAuditMetrics(response.data))
        .catch(error => console.error('Error refreshing audit metrics:', error));
    }, 300000);

    return () => clearInterval(interval);
  }, []);

  const handleVatRateChange = (event) => {
    setVatRates({
      ...vatRates,
      [event.target.name]: event.target.value
    });
  };

  const handleVatRateSubmit = async (event) => {
    event.preventDefault();
    try {
      await api.post('/admin/vat-rates', vatRates);
      alert('VAT rates updated successfully');
    } catch (error) {
      console.error('Error updating VAT rates:', error);
      alert('Failed to update VAT rates');
    }
  };

  const handleNewUserChange = (event) => {
    const { name, value, checked } = event.target;
    setNewUser({
      ...newUser,
      [name]: name === 'has_free_access' ? checked : value
    });
  };

  const handleCreateUser = async (event) => {
    event.preventDefault();
    try {
      const userData = {
        ...newUser,
        name: newUser.email.split('@')[0],
        account_name: newUser.email.split('@')[0],
        account_details: {
          is_active: true,
          subscription_status: 'active',
          manager_can_view_notes: false
        }
      };

      await api.post('/admin/create-user', userData);
      alert('User and account created successfully');
      
      const [usersResponse, accountsResponse] = await Promise.all([
        api.get('/admin/users'),
        api.get('/admin/accounts')
      ]);
      
      setUsers(usersResponse.data);
      setAccounts(accountsResponse.data);
      
      setNewUser({ 
        email: '', 
        tempPassword: '', 
        role: 'ACCOUNT_OWNER', 
        has_free_access: true 
      });
    } catch (error) {
      console.error('Error creating user:', error);
      alert('Failed to create user and account');
    }
  };

  const handleToggleFreeAccess = async (userId, currentStatus) => {
    try {
      await api.put(`/admin/users/${userId}/toggle-free-access`);
      const response = await api.get('/admin/users');
      setUsers(response.data);
      alert('User free access status updated successfully');
    } catch (error) {
      console.error('Error updating user free access status:', error);
      alert('Failed to update user free access status');
    }
  };

  const handleToggleAccountStatus = async (accountId) => {
    try {
      await api.put(`/admin/toggle-account-status/${accountId}`);
      const response = await api.get('/admin/accounts');
      setAccounts(response.data);
      alert('Account status updated successfully');
    } catch (error) {
      console.error('Error updating account status:', error);
      alert('Failed to update account status');
    }
  };

  const handleToggleAccountFreeAccess = async (accountId) => {
    try {
      await api.put(`/admin/accounts/${accountId}/toggle-free-access`);
      const response = await api.get('/admin/accounts');
      setAccounts(response.data);
      alert('Account free access status updated successfully');
    } catch (error) {
      console.error('Error updating account free access:', error);
      alert('Failed to update account free access');
    }
  };

  if (loading) return <CircularProgress />;
  if (error) return <Alert severity="error">{error}</Alert>;

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" gutterBottom>Admin Dashboard</Typography>
      
      {/* Audit Metrics Section */}
      <Grid container spacing={3} sx={{ mb: 3 }}>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>Security Audit Metrics (Last 24h)</Typography>
        </Grid>
        
        <Grid item xs={12} md={3}>
          <Paper sx={{ p: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box>
              <Typography color="textSecondary" variant="subtitle2">Total Events</Typography>
              <Typography variant="h4">{auditMetrics?.totalEvents || 0}</Typography>
            </Box>
            <Assessment color="primary" />
          </Paper>
        </Grid>

        <Grid item xs={12} md={3}>
          <Paper sx={{ p: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box>
              <Typography color="textSecondary" variant="subtitle2">Security Violations</Typography>
              <Typography variant="h4" color={auditMetrics?.securityViolations > 0 ? "error" : "inherit"}>
                {auditMetrics?.securityViolations || 0}
              </Typography>
            </Box>
            <SecurityOutlined color={auditMetrics?.securityViolations > 0 ? "error" : "primary"} />
          </Paper>
        </Grid>

        <Grid item xs={12} md={3}>
          <Paper sx={{ p: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box>
              <Typography color="textSecondary" variant="subtitle2">Active Users</Typography>
              <Typography variant="h4">{auditMetrics?.activeUsers || 0}</Typography>
            </Box>
            <People color="primary" />
          </Paper>
        </Grid>

        <Grid item xs={12} md={3}>
          <Paper sx={{ p: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box>
              <Typography color="textSecondary" variant="subtitle2">Avg Response Time</Typography>
              <Typography variant="h4">{auditMetrics?.avgResponseTime || 0}ms</Typography>
            </Box>
            <Speed color="primary" />
          </Paper>
        </Grid>

        {/* Activity Timeline Chart */}
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>Activity Timeline</Typography>
            <Box sx={{ height: 300 }}>
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={auditMetrics?.timeline || []}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="time" />
                  <YAxis />
                  <Tooltip />
                  <Line type="monotone" dataKey="events" stroke="#8884d8" name="Events" />
                  <Line type="monotone" dataKey="errors" stroke="#ff0000" name="Errors" />
                </LineChart>
              </ResponsiveContainer>
            </Box>
          </Paper>
        </Grid>

        {/* Recent Errors */}
        {auditMetrics?.errors && auditMetrics.errors.length > 0 && (
          <Grid item xs={12}>
            <Paper sx={{ p: 2 }}>
              <Typography variant="h6" gutterBottom>Recent Errors</Typography>
              <Timeline>
                {auditMetrics.errors.map((error, index) => (
                  <TimelineItem key={index}>
                    <TimelineSeparator>
                      <TimelineDot color="error">
                        <ErrorOutline />
                      </TimelineDot>
                      {index < auditMetrics.errors.length - 1 && <TimelineConnector />}
                    </TimelineSeparator>
                    <TimelineContent>
                      <Typography variant="subtitle2" color="error">
                        {error.message}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {error.count} occurrences
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                ))}
              </Timeline>
            </Paper>
          </Grid>
        )}
      </Grid>

      {/* Existing dashboard sections */}
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>Total Users</Typography>
            <Typography variant="h4">{dashboardData?.total_users}</Typography>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>VAT Rates</Typography>
            <form onSubmit={handleVatRateSubmit}>
              <TextField
                label="Standard Rate"
                name="standard_rate"
                value={vatRates.standard_rate}
                onChange={handleVatRateChange}
                type="number"
                fullWidth
                margin="normal"
              />
              <TextField
                label="First Year Rate"
                name="first_year_rate"
                value={vatRates.first_year_rate}
                onChange={handleVatRateChange}
                type="number"
                fullWidth
                margin="normal"
              />
              <TextField
                label="Following Year Rate"
                name="following_year_rate"
                value={vatRates.following_year_rate}
                onChange={handleVatRateChange}
                type="number"
                fullWidth
                margin="normal"
              />
              <Button type="submit" variant="contained" color="primary">
                Update VAT Rates
              </Button>
            </form>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>Create New User</Typography>
            <form onSubmit={handleCreateUser}>
              <TextField
                label="Email"
                name="email"
                value={newUser.email}
                onChange={handleNewUserChange}
                fullWidth
                margin="normal"
                required
              />
              <TextField
                label="Temporary Password"
                name="tempPassword"
                value={newUser.tempPassword}
                onChange={handleNewUserChange}
                type="password"
                fullWidth
                margin="normal"
                required
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={newUser.has_free_access}
                    onChange={handleNewUserChange}
                    name="has_free_access"
                    color="primary"
                  />
                }
                label="Free Access"
              />
              <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
                Create User
              </Button>
            </form>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>User List</Typography>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Role</TableCell>
                    <TableCell>Free Access</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.map((user) => (
                    <TableRow key={user.id}>
                      <TableCell>{user.id}</TableCell>
                      <TableCell>{user.name}</TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>{user.role}</TableCell>
                      <TableCell>{user.has_free_access ? 'Yes' : 'No'}</TableCell>
                      <TableCell>
                        <Button 
                          onClick={() => handleToggleFreeAccess(user.id, user.has_free_access)}
                          variant="outlined"
                          color={user.has_free_access ? "secondary" : "primary"}
                        >
                          {user.has_free_access ? 'Remove Free Access' : 'Grant Free Access'}
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>Account Management</Typography>
            <TableContainer>
              <Table>
              <TableHead>
                  <TableRow>
                    <TableCell>Account Name</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Subscription Status</TableCell>
                    <TableCell>Created</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {accounts.map((account) => (
                    <TableRow key={account.id}>
                      <TableCell>{account.name}</TableCell>
                      <TableCell>
                        <span style={{ color: account.is_active ? 'green' : 'red' }}>
                          {account.is_active ? 'Active' : 'Inactive'}
                        </span>
                      </TableCell>
                      <TableCell>{account.subscription_status}</TableCell>
                      <TableCell>
                        {new Date(account.created_at).toLocaleDateString()}
                      </TableCell>
                      <TableCell>
                        <Button 
                          onClick={() => handleToggleAccountStatus(account.id)}
                          variant="outlined"
                          color={account.is_active ? "secondary" : "primary"}
                          sx={{ mr: 1 }}
                        >
                          {account.is_active ? 'Deactivate' : 'Activate'}
                        </Button>
                        <Button
                          onClick={() => handleToggleAccountFreeAccess(account.id)}
                          variant="outlined"
                          color="primary"
                        >
                          Toggle Free Access
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AdminDashboard;