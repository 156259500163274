import React, { useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  Grid,
  Button,
  TextField,
  MenuItem,
  CircularProgress,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Card,
  CardContent,
  Divider,
  Chip
} from '@mui/material';
import {
  Download,
  Refresh,
  AccountBalance,
  TrendingUp,
  TrendingDown,
  Timeline,
  RepeatOne,
  MonetizationOn,
  CurrencyPound
} from '@mui/icons-material';
import dayjs from 'dayjs';
import DatePickerComponent from './DatePickerComponent';
import api from './axiosConfig';

const CashflowReport = () => {
  const [startDate, setStartDate] = useState(dayjs().startOf('month'));
  const [endDate, setEndDate] = useState(dayjs().endOf('month'));
  const [monthsAhead, setMonthsAhead] = useState(3);
  const [exportFormat, setExportFormat] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [report, setReport] = useState(null);

const handleGenerateReport = async () => {
  setLoading(true);
  setError(null);

  try {
    const response = await api.get('/reports/cashflow', {  
      params: {
        start_date: startDate.format('YYYY-MM-DD'), 
        end_date: endDate.format('YYYY-MM-DD'),     
        months_ahead: monthsAhead 
      }
    });

    setReport(response.data);
  } catch (err) {
    setError(err.response?.data?.error || 'Failed to generate report');
  } finally {
    setLoading(false);
  }
};

  const handleExport = async (format) => {
    try {
      const response = await api.get('/reports/cashflow', {
        params: {
          start_date: startDate.format('YYYY-MM-DD'), 
          end_date: endDate.format('YYYY-MM-DD'), 
          months_ahead: monthsAhead,
          export_format: format
        },
        responseType: 'blob'
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `cashflow_forecast.${format.toLowerCase()}`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (err) {
      setError('Failed to export report');
    }
  };

  const getMonthName = (monthStr) => {
    return dayjs(monthStr).format('MMMM YYYY');
  };

  return (
    <Box>
      {/* Controls */}
      <Paper sx={{ p: 3, mb: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <DatePickerComponent
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Forecast Months Ahead"
              type="number"
              value={monthsAhead}
              onChange={(e) => setMonthsAhead(Number(e.target.value))}
              inputProps={{ min: 1, max: 12 }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
              <Button
                variant="contained"
                onClick={handleGenerateReport}
                disabled={loading}
                startIcon={loading ? <CircularProgress size={20} /> : <Refresh />}
              >
                Generate Forecast
              </Button>
              <TextField
                select
                label="Export Format"
                value={exportFormat}
                onChange={(e) => setExportFormat(e.target.value)}
                sx={{ minWidth: 120 }}
              >
                <MenuItem value="CSV">CSV</MenuItem>
                <MenuItem value="EXCEL">Excel</MenuItem>
              </TextField>
              <Button
                variant="outlined"
                onClick={() => handleExport(exportFormat)}
                disabled={!report || !exportFormat}
                startIcon={<Download />}
              >
                Export
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Paper>


      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}

      {report && (
        <Box>
          {/* Current Position */}
          <Grid container spacing={3} sx={{ mb: 3 }}>
            <Grid item xs={12} md={4}>
              <Card>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <AccountBalance color="primary" />
                    <Typography variant="h6">Current Cash Position</Typography>
                  </Box>
                  <Typography 
                    variant="h4" 
                    sx={{ 
                      mt: 2,
                      color: report.current_position.current_position >= 0 
                        ? 'success.main' 
                        : 'error.main'
                    }}
                  >
                    £{report.current_position.current_position.toFixed(2)}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Timeline color="info" />
                    <Typography variant="h6">Projected End Balance</Typography>
                  </Box>
                  <Typography 
                    variant="h4" 
                    sx={{ 
                      mt: 2,
                      color: report.forecast_summary.ending_balance >= 0 
                        ? 'success.main' 
                        : 'error.main'
                    }}
                  >
                    £{report.forecast_summary.ending_balance.toFixed(2)}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {monthsAhead} month projection
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <CurrencyPound color="warning" size={24} />
                    <Typography variant="h6">Net Cashflow</Typography>
                  </Box>
                  <Typography 
                    variant="h4" 
                    sx={{ 
                      mt: 2,
                      color: report.forecast_summary.net_projected_cashflow >= 0 
                        ? 'success.main' 
                        : 'error.main'
                    }}
                  >
                    £{report.forecast_summary.net_projected_cashflow.toFixed(2)}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Projected change
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          {/* Recurring Expenses */}
          <Paper sx={{ p: 3, mb: 3 }}>
            <Typography variant="h6" gutterBottom>
              Recurring Expenses
            </Typography>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Supplier</TableCell>
                    <TableCell>Frequency</TableCell>
                    <TableCell align="right">Average Amount</TableCell>
                    <TableCell align="right">Min Amount</TableCell>
                    <TableCell align="right">Max Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {report.recurring_expenses.map((expense, index) => (
                    <TableRow key={index}>
                      <TableCell>{expense.supplier}</TableCell>
                      <TableCell>
                        <Chip 
                          label={expense.frequency} 
                          color={expense.frequency === 'Monthly' ? 'primary' : 'default'}
                          size="small"
                        />
                      </TableCell>
                      <TableCell align="right">
                        £{expense.average_amount.toFixed(2)}
                      </TableCell>
                      <TableCell align="right">
                        £{expense.min_amount.toFixed(2)}
                      </TableCell>
                      <TableCell align="right">
                        £{expense.max_amount.toFixed(2)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>

          {/* Monthly Projections */}
          <Paper sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              Monthly Cashflow Projections
            </Typography>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Month</TableCell>
                    <TableCell align="right">Projected Income</TableCell>
                    <TableCell align="right">Projected Expenses</TableCell>
                    <TableCell align="right">Net Cashflow</TableCell>
                    <TableCell align="right">Running Balance</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {report.monthly_projections.map((month, index) => (
                    <TableRow key={index}>
                      <TableCell>{getMonthName(month.month)}</TableCell>
                      <TableCell align="right">
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                          <TrendingUp 
                            sx={{ 
                              mr: 1, 
                              color: 'success.main',
                              fontSize: '1rem'
                            }} 
                          />
                          £{month.projected_income.toFixed(2)}
                        </Box>
                      </TableCell>
                      <TableCell align="right">
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                          <TrendingDown 
                            sx={{ 
                              mr: 1, 
                              color: 'error.main',
                              fontSize: '1rem'
                            }} 
                          />
                          £{month.projected_expenses.toFixed(2)}
                        </Box>
                      </TableCell>
                      <TableCell align="right">
                        <Typography
                          sx={{
                            color: month.net_cashflow >= 0 ? 'success.main' : 'error.main',
                            fontWeight: 'bold'
                          }}
                        >
                          £{month.net_cashflow.toFixed(2)}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography
                          sx={{
                            color: month.running_balance >= 0 ? 'success.main' : 'error.main',
                            fontWeight: 'bold'
                          }}
                        >
                          £{month.running_balance.toFixed(2)}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            {/* Detailed Breakdown for Each Month */}
            <Box sx={{ mt: 4 }}>
              <Typography variant="h6" gutterBottom>
                Detailed Monthly Breakdown
              </Typography>
              {report.monthly_projections.map((month, index) => (
                <Card key={index} sx={{ mb: 2 }}>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      {getMonthName(month.month)}
                    </Typography>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <Typography variant="subtitle1" gutterBottom>
                          Income Breakdown
                        </Typography>
                        <Box sx={{ pl: 2 }}>
                          <Typography variant="body2">
                            Projected: £{month.breakdown.income.projected_amount.toFixed(2)}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            Range: £{month.breakdown.income.min_projection.toFixed(2)} - 
                            £{month.breakdown.income.max_projection.toFixed(2)}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant="subtitle1" gutterBottom>
                          Expense Categories
                        </Typography>
                        {Object.entries(month.breakdown.expenses).map(([category, data], idx) => (
                          <Box key={idx} sx={{ pl: 2, mb: 1 }}>
                            <Typography variant="body2">
                              {category}: £{data.projected_amount.toFixed(2)}
                            </Typography>
                            <Typography variant="body2" color="text.secondary" sx={{ fontSize: '0.8rem' }}>
                              Range: £{data.min_projection.toFixed(2)} - 
                              £{data.max_projection.toFixed(2)}
                            </Typography>
                          </Box>
                        ))}
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              ))}
            </Box>
          </Paper>
        </Box>
      )}
    </Box>
  );
};

export default CashflowReport;