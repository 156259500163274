import errorHandler from './utils/errorHandler';
import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from './AuthContext';
import api from './axiosConfig';
import * as Yup from 'yup';
import {
  Box,
  TextField,
  Button,
  Typography,
  Paper,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Snackbar,
  Alert,
  CircularProgress,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  FormGroup,
  Tabs,
  Tab
} from '@mui/material';
import PersonalInfo from './PersonalInfo';
import Holidays from './Holidays';
import Absences from './Absences';
import TrainingManagement from './TrainingManagement';
import Disciplinary from './Disciplinary';
import Notes from './Notes';
import ReviewForm from './ReviewForm';

const initialEmployeeState = {
  name: '',
  email: '',
  role: '',
  hourly_rate: '',
  address_line1: '',
  address_line2: '',
  address_line3: '',
  postcode: '',
  contact_number: '',
  emergency_contact: '',
  emergency_number: '',
  date_of_birth: '',
  national_insurance_number: '',
  start_date: '',
  active: true,
  commission_services: '',
  commission_products: '',
  full_time_equivalent: 1
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  role: Yup.string().required('Role is required'),
  hourly_rate: Yup.number().positive('Hourly rate must be positive').required('Hourly rate is required'),
  address_line1: Yup.string().required('Address line 1 is required'),
  postcode: Yup.string().required('Postcode is required'),
  contact_number: Yup.string().required('Contact number is required'),
  date_of_birth: Yup.date().required('Date of birth is required'),
  national_insurance_number: Yup.string().required('National Insurance number is required'),
  start_date: Yup.date().required('Start date is required'),
});

const EmployeeCard = () => {
  const { user } = useAuth();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedEmployee, setSelectedEmployee] = useState(initialEmployeeState);
  const [originalEmployee, setOriginalEmployee] = useState({});
  const [errors, setErrors] = useState({});
  const [mode, setMode] = useState('View');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [activeTab, setActiveTab] = useState('PersonalInfo');
  const [isLoading, setIsLoading] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [selectedReview, setSelectedReview] = useState(null);
  const [openReviewDialog, setOpenReviewDialog] = useState(false);
  const [reviewMode, setReviewMode] = useState('view');

  const getRoleOptions = useCallback(() => {
    const { role: currentUserRole } = user;
    
    // Base role options that everyone can assign
    let allowedRoles = [
      { value: 'ACCOUNT_USER', label: 'Account User' }
    ];

    // Add additional roles based on user's role
    if (currentUserRole === 'ADMIN') {
      allowedRoles = [
        { value: 'ADMIN', label: 'Admin' },
        { value: 'ACCOUNT_OWNER', label: 'Account Owner' },
        { value: 'ACCOUNT_MANAGER', label: 'Account Manager' },
        { value: 'ACCOUNT_USER', label: 'Account User' }
      ];
    } else if (currentUserRole === 'ACCOUNT_OWNER') {
      allowedRoles = [
        { value: 'ACCOUNT_MANAGER', label: 'Account Manager' },
        { value: 'ACCOUNT_USER', label: 'Account User' }
      ];
    } else if (currentUserRole === 'ACCOUNT_MANAGER') {
      allowedRoles = [
        { value: 'ACCOUNT_USER', label: 'Account User' }
      ];
    }

    return allowedRoles;
  }, [user]);

  const fetchEmployeeDetails = useCallback(async (employeeId) => {
    errorHandler.logApiCall('Fetching employee details...', employeeId);
    setIsLoading(true);
    try {
      const response = await api.get(`/employees/${employeeId}`);
      errorHandler.logDebug('EmployeeCard', 'Employee details response:', response.data);
      setSelectedEmployee(response.data || initialEmployeeState);
      setOriginalEmployee(response.data || {});
    } catch (err) {
      console.error('Error fetching employee details:', err);
      setSnackbar({
        open: true,
        message: 'Failed to fetch employee details: ' + (err.response?.data?.error || err.message || 'Unknown error'),
        severity: 'error'
      });
    } finally {
      setIsLoading(false);
    }
  }, []);

  const fetchReviews = useCallback(async (employeeId) => {
    try {
      errorHandler.logDebug('EmployeeCard', `Fetching reviews for employee ${employeeId}`);
      const response = await api.get(`/reviews/employee/${employeeId}`);
      errorHandler.logDebug('EmployeeCard', 'Received reviews:', response.data);
      setReviews(response.data);
    } catch (error) {
      console.error('Error fetching reviews:', error);
      setSnackbar({
        open: true,
        message: `Error fetching reviews: ${error.response?.data?.error || error.message}`,
        severity: 'error'
      });
    }
  }, []);

  useEffect(() => {
    if (selectedEmployee?.id) {
      fetchReviews(selectedEmployee.id);
    }
  }, [selectedEmployee, fetchReviews]);

  useEffect(() => {
    errorHandler.logDebug('EmployeeCard', 'EmployeeCard component mounted');
    return () => {
      errorHandler.logDebug('EmployeeCard', 'EmployeeCard component unmounted');
    };
  }, []);

  useEffect(() => {
    errorHandler.logDebug('EmployeeCard', 'useEffect triggered - selectedEmployee:', selectedEmployee?.id, 'mode:', mode);
    if (selectedEmployee?.id && mode === 'View') {
      fetchEmployeeDetails(selectedEmployee.id);
    } else {
      setIsLoading(false);
    }
  }, [selectedEmployee?.id, mode, fetchEmployeeDetails]);

  const handleSearch = async (event) => {
    event.preventDefault();
    setErrors({});
    errorHandler.logDebug('EmployeeCard', 'Searching for employee:', searchTerm);
    
    if (!searchTerm.trim()) {
      setErrors({ search: 'Please enter an employee name to search.' });
      return;
    }
    
    setIsLoading(true);
    try {
      const response = await api.get('/employees/search', {
        params: { query: searchTerm }
      });
      errorHandler.logDebug('EmployeeCard', 'Search response:', response.data);
      
      if (response.data.error) {
        setErrors({ search: response.data.error });
        setSelectedEmployee(initialEmployeeState);
      } else if (response.data.employees && response.data.employees.length > 0) {
        setSelectedEmployee(response.data.employees[0] || initialEmployeeState);
        setMode('View');
        setSnackbar({ open: true, message: 'Employee found.', severity: 'success' });
      } else {
        setSelectedEmployee(initialEmployeeState);
        setSnackbar({ open: true, message: 'No employee found.', severity: 'info' });
      }
    } catch (err) {
      console.error('Error searching employee:', err);
      setErrors({ search: 'An error occurred while fetching employee data.' });
      setSelectedEmployee(initialEmployeeState);
      setSnackbar({
        open: true,
        message: 'Error searching employee: ' + (err.response?.data?.error || err.message || 'Unknown error'),
        severity: 'error'
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSave = async () => {
    errorHandler.logDebug('EmployeeCard', 'Save button clicked');
    errorHandler.logDebug('EmployeeCard', 'Current mode:', mode);
    errorHandler.logDebug('EmployeeCard', 'Selected employee:', selectedEmployee);
    errorHandler.logDebug('EmployeeCard', 'User role:', user?.role);

    setErrors({});
    try {
      await validationSchema.validate(selectedEmployee, { abortEarly: false });
      
      const personalInfoFields = [
        'name', 'email', 'role', 'hourly_rate', 'address_line1', 'address_line2',
        'address_line3', 'postcode', 'contact_number', 'emergency_contact',
        'emergency_number', 'date_of_birth', 'national_insurance_number',
        'start_date', 'active', 'commission_services', 'commission_products',
        'full_time_equivalent'
      ];
      
      const updatedData = Object.fromEntries(
        Object.entries(selectedEmployee).filter(([key]) => personalInfoFields.includes(key))
      );

      let response;
      if (mode === 'New') {
        errorHandler.logDebug('EmployeeCard', 'Creating new employee...');
        response = await api.post('/employees', updatedData);
      } else if (mode === 'Update' && selectedEmployee?.id) {
        errorHandler.logDebug('EmployeeCard', 'Updating employee...', selectedEmployee.id);
        response = await api.put(`/employees/${selectedEmployee.id}`, updatedData);
      } else {
        throw new Error('Invalid mode or missing employee ID for update');
      }

      errorHandler.logApiCall('API response:', response);

      if (response.data.error) {
        throw new Error(response.data.error);
      }

      setSelectedEmployee(response.data.employee || initialEmployeeState);
      setOriginalEmployee(response.data.employee || {});
      setMode('View');
      setSnackbar({ 
        open: true, 
        message: mode === 'New' ? 'New employee created successfully' : 'Employee data updated successfully', 
        severity: 'success'
      });
    } catch (error) {
      console.error('Error in handleSave:', error);
      if (error instanceof Yup.ValidationError) {
        const validationErrors = {};
        error.inner.forEach((err) => {
          validationErrors[err.path] = err.message;
        });
        setErrors(validationErrors);
      } else {
        console.error('API Error:', error.response?.data);
        setSnackbar({ 
          open: true, 
          message: `Error: ${error.response?.data?.error || error.message || 'Unknown error'}`,
          severity: 'error' 
        });
      }
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setSelectedEmployee(prev => ({ ...prev, [name]: value }));
    if (errors[name]) {
      setErrors(prev => ({ ...prev, [name]: '' }));
    }
  };

  const handleModeChange = (newMode) => {
    errorHandler.logDebug('EmployeeCard', 'Mode changed to:', newMode);
    if (newMode === 'New') {
      setSelectedEmployee(initialEmployeeState);
    } else if (newMode === 'View') {
      setSelectedEmployee({ ...originalEmployee });
    }
    setMode(newMode);
    setErrors({});
  };

  const handleActiveStatusChange = (event) => {
    setSelectedEmployee(prev => ({ ...prev, active: event.target.value === 'active' }));
  };

  const handleViewFullReview = (review) => {
    setSelectedReview(review);
    setReviewMode('view');
    setOpenReviewDialog(true);
  };

  const handleNewReview = () => {
    setSelectedReview({
      employee_id: selectedEmployee.id,
      employee_name: selectedEmployee.name,
      // Initialize other fields as needed
    });
    setReviewMode('new');
    setOpenReviewDialog(true);
  };

  const handleEditReview = (review) => {
    setSelectedReview(review);
    setReviewMode('edit');
    setOpenReviewDialog(true);
  };

  const handleReviewChange = (e) => {
    const { name, value } = e.target;
    setSelectedReview(prev => ({ ...prev, [name]: value }));
  };

  const handleReviewCheckboxChange = (section, value) => {
    setSelectedReview(prev => {
      const updatedSection = prev[section].includes(value)
        ? prev[section].filter(item => item !== value)
        : [...prev[section], value];
      return { ...prev, [section]: updatedSection };
    });
  };

  const handleReviewSubmit = async () => {
    try {
      let response;
      if (reviewMode === 'new') {
        response = await api.post(`/reviews/employee/${selectedEmployee.id}`, selectedReview);
      } else if (reviewMode === 'edit') {
        response = await api.put(`/reviews/${selectedReview.id}`, selectedReview);
      }
      setSnackbar({ open: true, message: 'Review saved successfully', severity: 'success' });
      setOpenReviewDialog(false);
      fetchReviews(selectedEmployee.id);
    } catch (error) {
      console.error('Error saving review:', error);
      setSnackbar({ open: true, message: 'Failed to save review', severity: 'error' });
    }
  };

  const handleSaveDraft = async () => {
    try {
      const response = await api.post(`/reviews/employee/${selectedEmployee.id}/draft`, selectedReview);
      setSnackbar({ open: true, message: 'Draft saved successfully', severity: 'success' });
      setOpenReviewDialog(false);
      fetchReviews(selectedEmployee.id);
    } catch (error) {
      console.error('Error saving draft:', error);
      setSnackbar({ open: true, message: 'Failed to save draft', severity: 'error' });
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return date instanceof Date && !isNaN(date) 
      ? date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })
      : 'Invalid Date';
  };

  const isEditable = mode === 'New' || mode === 'Update';
  const canEditEmployee = user?.role === 'ACCOUNT_OWNER' || user?.role === 'ACCOUNT_MANAGER';

  const isSaveButtonEnabled = () => {
    if (mode === 'New') {
      return canEditEmployee;
    } else if (mode === 'Update') {
      return isEditable && canEditEmployee;
    }
    return false;
  };

  const renderReviewTab = () => (
    <Box>
      <Button onClick={handleNewReview} variant="contained" color="primary" sx={{ mb: 2 }}>
        New Review
      </Button>
      <Button onClick={() => fetchReviews(selectedEmployee.id)} variant="outlined" sx={{ ml: 2, mb: 2 }}>
        Refresh Reviews
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Review Date</TableCell>
              <TableCell>Manager</TableCell>
              <TableCell>Job Title</TableCell>
              <TableCell>Performance Score</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reviews.map((review) => (
              <TableRow key={review.id}>
                <TableCell>{formatDate(review.date_of_meeting)}</TableCell>
                <TableCell>{review.manager}</TableCell>
                <TableCell>{review.job_title}</TableCell>
                <TableCell>{review.performance_score}</TableCell>
                <TableCell>
                  <Button variant="outlined" onClick={() => handleViewFullReview(review)} sx={{ mr: 1 }}>
                    View
                  </Button>
                  <Button variant="outlined" onClick={() => handleEditReview(review)}>
                    Edit
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={openReviewDialog} onClose={() => setOpenReviewDialog(false)} maxWidth="md" fullWidth>
        <DialogTitle>
          {reviewMode === 'view' ? 'View Review' : reviewMode === 'edit' ? 'Edit Review' : 'New Review'}
        </DialogTitle>
        <DialogContent>
          <ReviewForm 
            reviewData={selectedReview} 
            handleChange={handleReviewChange}
            handleCheckboxChange={handleReviewCheckboxChange}
            isReadOnly={reviewMode === 'view'}
          />
        </DialogContent>
        <DialogActions>
          {reviewMode !== 'view' && (
            <>
              <Button onClick={handleSaveDraft} color="primary">
                Save Draft
              </Button>
              <Button onClick={handleReviewSubmit} color="primary">
                Submit Review
              </Button>
            </>
          )}
          <Button onClick={() => setOpenReviewDialog(false)} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );

  const renderTabContent = () => {
    errorHandler.logDebug('EmployeeCard', 'Rendering tab content', { activeTab, selectedEmployee });
    
    if (!selectedEmployee?.id && activeTab !== 'PersonalInfo' && mode !== 'New') {
      return <Typography>Please select an employee first.</Typography>;
    }

    switch (activeTab) {
      case 'PersonalInfo':
        return (
          <PersonalInfo 
            employee={selectedEmployee} 
            isEditable={isEditable} 
            handleChange={handleChange}
            errors={errors}
            roleOptions={getRoleOptions()}
          />
        );
      case 'Holidays':
        return selectedEmployee?.id ? <Holidays employee={selectedEmployee} isEditable={isEditable} /> : null;
      case 'Absences':
        return selectedEmployee?.id ? <Absences employee={selectedEmployee} /> : null;
      case 'Training':
        return selectedEmployee?.id ? <TrainingManagement employee={selectedEmployee} isEditable={isEditable} /> : null;
      case 'Reviews':
        return renderReviewTab();
      case 'Disciplinary':
        return selectedEmployee?.id ? <Disciplinary employee={selectedEmployee} isEditable={isEditable} /> : null;
      case 'Notes':
        return selectedEmployee?.id ? <Notes employee={selectedEmployee} isEditable={isEditable} /> : null;
      default:
        return null;
    }
  };

  return (
    <Paper elevation={3} sx={{ p: 3, maxWidth: 1200, margin: '0 auto' }}>
      <Typography variant="h4" gutterBottom>Employee Card</Typography>
      
      <Box sx={{ mb: 3 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Search employee by name"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              error={!!errors.search}
              helperText={errors.search}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Button fullWidth variant="contained" onClick={handleSearch}>Search</Button>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel>Mode</InputLabel>
              <Select
                value={mode}
                onChange={(e) => handleModeChange(e.target.value)}
                label="Mode"
              >
                <MenuItem value="New">New</MenuItem>
                <MenuItem value="View">View</MenuItem>
                <MenuItem value="Update">Update</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ mb: 3 }}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Employee Status</FormLabel>
          <RadioGroup
            row
            name="active-status"
            value={selectedEmployee.active ? 'active' : 'inactive'}
            onChange={handleActiveStatusChange}
          >
            <FormControlLabel value="active" control={<Radio />} label="Active" disabled={!isEditable} />
            <FormControlLabel value="inactive" control={<Radio />} label="Inactive" disabled={!isEditable} />
          </RadioGroup>
        </FormControl>
      </Box>

      <Tabs value={activeTab} onChange={(e, newValue) => setActiveTab(newValue)} sx={{ mb: 2 }}>
        <Tab label="Personal Info" value="PersonalInfo" />
        <Tab label="Holidays" value="Holidays" />
        <Tab label="Absences" value="Absences" />
        <Tab label="Training" value="Training" />
        <Tab label="Reviews" value="Reviews" />
        <Tab label="Disciplinary" value="Disciplinary" />
        <Tab label="Notes" value="Notes" />
      </Tabs>

      <Box sx={{ mb: 3, opacity: selectedEmployee.active === false ? 0.5 : 1 }}>
        {renderTabContent()}
      </Box>

      <Box>
        <Button 
          variant="contained" 
          onClick={handleSave} 
          disabled={!isSaveButtonEnabled()}
        >
          {mode === 'New' ? 'Create Employee' : 'Save Changes'}
        </Button>
        {!isSaveButtonEnabled() && (
          <Typography color="error" sx={{ mt: 1 }}>
            {!isEditable ? "Not in editable mode" : "User doesn't have permission to edit"}
          </Typography>
        )}
      </Box>

      {isLoading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <CircularProgress />
        </Box>
      )}

      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
      >
       <Alert 
            onClose={() => setSnackbar(prev => ({ ...prev, open: false }))} 
            severity={snackbar.severity}
            sx={{ width: '100%' }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Paper>
    );  // Closing return statement
  }; // Closing EmployeeCard component

export default EmployeeCard;