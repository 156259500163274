import React from 'react';
import { useAuth } from './AuthContext';
import { Navigate, Outlet } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Container,
  Button,
  Divider
} from '@mui/material';

const RentalLayout = () => {
  const { user, logout } = useAuth();

  // Only allow RENTAL_USER and admin roles
  if (!user || (user.role !== 'RENTAL_USER' && 
      user.role !== 'ACCOUNT_OWNER' && 
      user.role !== 'ACCOUNT_MANAGER')) {
    return <Navigate to="/unauthorized" />;
  }

  const handleLogout = () => {
    logout();
  };

  const isAdmin = user.role === 'ACCOUNT_OWNER' || user.role === 'ACCOUNT_MANAGER';

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <AppBar 
        position="static"
        sx={{
          backgroundColor: isAdmin ? '#1976d2' : '#2e7d32'  // Different color for rental users
        }}
      >
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {isAdmin ? 'Rental Management Portal' : 'Rental Portal'}
          </Typography>
          <Typography sx={{ mr: 2 }}>
            Welcome, {user.email}
          </Typography>
          <Divider orientation="vertical" flexItem sx={{ mx: 2, backgroundColor: 'white' }} />
          <Typography variant="body2" sx={{ mr: 2 }}>
            {user.role.replace('_', ' ')}
          </Typography>
          <Button color="inherit" onClick={handleLogout}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>

      <Container 
        sx={{ 
          flexGrow: 1, 
          mt: 4,
          mb: 4,
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Outlet />
      </Container>

      <Box 
        component="footer" 
        sx={{ 
          py: 3, 
          backgroundColor: 'background.paper',
          borderTop: 1,
          borderColor: 'divider'
        }}
      >
        <Container maxWidth="sm">
          <Typography variant="body2" color="text.secondary" align="center">
            © {new Date().getFullYear()} {isAdmin ? 'Rental Management System' : 'Rental Portal'}
          </Typography>
        </Container>
      </Box>
    </Box>
  );
};

export default RentalLayout;