import errorHandler from './utils/errorHandler';
import React, { createContext, useState, useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import api from './axiosConfig';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(() => {
        try {
            const savedUser = localStorage.getItem('user');
            return savedUser ? JSON.parse(savedUser) : null;
        } catch (error) {
            console.error('Error parsing stored user:', error);
            return null;
        }
    });
    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const navigate = useNavigate();

    // Helper to safely store tokens
    const storeAuthData = (accessToken, refreshToken, userData) => {
        if (!accessToken || !refreshToken || !userData) {
            console.error('Missing required auth data');
            return false;
        }

        try {
            // Store tokens separately to avoid concatenation
            localStorage.setItem('access_token', accessToken);
            localStorage.setItem('refresh_token', refreshToken);
            localStorage.setItem('user', JSON.stringify(userData));

            // Update API headers
            api.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
            return true;
        } catch (error) {
            console.error('Error storing auth data:', error);
            return false;
        }
    };

    const login = useCallback(async (email, password) => {
        setIsLoggingIn(true);
        try {
            // Clear existing auth data
            localStorage.clear();
            delete api.defaults.headers.common['Authorization'];

            const response = await api.post('/auth/login', { email, password });
            console.log('Login response:', {
                status: response.status,
                hasAccessToken: !!response.data?.access_token,
                hasRefreshToken: !!response.data?.refresh_token,
                hasUser: !!response.data?.user
            });

            if (!response.data?.access_token || !response.data?.refresh_token) {
                throw new Error('Invalid authentication response');
            }

            const userData = response.data.user || {};
            
            // Store auth data
            const stored = storeAuthData(
                response.data.access_token,
                response.data.refresh_token,
                userData
            );

            if (!stored) {
                throw new Error('Failed to store authentication data');
            }

            setUser(userData);

            // Navigate based on role
            const roleRoutes = {
                ACCOUNT_MANAGER: '/manager_dashboard',
                ACCOUNT_OWNER: '/owner_dashboard',
                ACCOUNT_USER: '/user_dashboard'
            };

            navigate(roleRoutes[userData.role] || '/dashboard', { replace: true });
            return { success: true, user: userData };

        } catch (error) {
            console.error('Login error:', error);
            // Clean up any partial auth data
            localStorage.clear();
            delete api.defaults.headers.common['Authorization'];
            return {
                success: false,
                error: error.response?.data?.error || error.message || 'Login failed'
            };
        } finally {
            setIsLoggingIn(false);
        }
    }, [navigate]);

    const logout = useCallback(() => {
        try {
            localStorage.clear();
            delete api.defaults.headers.common['Authorization'];
            setUser(null);
            navigate('/login', { replace: true });
        } catch (error) {
            console.error('Logout error:', error);
        }
    }, [navigate]);

    const checkAuth = useCallback(() => {
        const accessToken = localStorage.getItem('access_token');
        const userData = localStorage.getItem('user');
        
        if (!accessToken || !userData) {
            logout();
            return false;
        }
        return true;
    }, [logout]);

    return (
        <AuthContext.Provider value={{ 
            user, 
            login, 
            logout, 
            isLoggingIn,
            isAuthenticated: !!user && checkAuth(),
        }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};

export default AuthContext;