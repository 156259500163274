import React, { useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  Button,
  Tabs,
  Tab,
  Alert,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';
import Papa from 'papaparse';
import api from '../axiosConfig';

const IncomeImport = ({ setMessage, setMessageType }) => {
  const [activeTab, setActiveTab] = useState('INSTRUCTIONS');
  const [validationErrors, setValidationErrors] = useState([]);
  const [importResults, setImportResults] = useState(null);

  // Constants for validation
  const MANDATORY_FIELDS = [
    'employee_id',     
    'date',           
    'sales_type',     
    'amount',         
    'payment_type'    
  ];

  const OPTIONAL_FIELDS = [
    'client_count',    
    'product_count',   
    'worked_today',    
    'start_time',      
    'end_time',        
    'hours_worked'     
  ];

  const VALID_SALES_TYPES = ['service', 'product'];
  const VALID_PAYMENT_TYPES = ['cash', 'card', 'voucher'];

  const validateIncomeData = (data) => {
    const errors = [];
    // Mandatory fields check
    MANDATORY_FIELDS.forEach(field => {
      if (!data[field]) {
        errors.push(`Missing required field: ${field}`);
      }
    });

    // Type validations
    if (data.sales_type && !VALID_SALES_TYPES.includes(data.sales_type.toLowerCase())) {
      errors.push(`Invalid sales type. Must be one of: ${VALID_SALES_TYPES.join(', ')}`);
    }

    if (data.payment_type && !VALID_PAYMENT_TYPES.includes(data.payment_type.toLowerCase())) {
      errors.push(`Invalid payment type. Must be one of: ${VALID_PAYMENT_TYPES.join(', ')}`);
    }

    // Date validation for UK format
    if (data.date) {
      const ukDateRegex = /^(\d{2})\/(\d{2})\/(\d{4})$/;
      if (!ukDateRegex.test(data.date)) {
        errors.push('Date must be in DD/MM/YYYY format');
      }
    }

    // Number validations - only for mandatory fields
    if (data.amount && isNaN(parseFloat(data.amount))) {
      errors.push('Amount must be a valid number');
    }

    if (data.employee_id && isNaN(parseInt(data.employee_id))) {
      errors.push('Employee ID must be a valid number');
    }

    // Optional field validations (only if they're provided)
    if (data.client_count && isNaN(parseInt(data.client_count))) {
      errors.push('Client count must be a valid number');
    }

    if (data.product_count && isNaN(parseInt(data.product_count))) {
      errors.push('Product count must be a valid number');
    }

    if (data.hours_worked && isNaN(parseFloat(data.hours_worked))) {
      errors.push('Hours worked must be a valid number');
    }

    // Time format validation (only if provided)
    const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;
    if (data.start_time && !timeRegex.test(data.start_time)) {
      errors.push('Start time must be in HH:MM format');
    }
    if (data.end_time && !timeRegex.test(data.end_time)) {
      errors.push('End time must be in HH:MM format');
    }

    return errors;
  };
  const processIncomeData = (row) => {
    const processed = {
      employee_id: parseInt(row.employee_id),
      service_sales: row.sales_type.toLowerCase() === 'service' ? parseFloat(row.amount) : 0,
      product_sales: row.sales_type.toLowerCase() === 'product' ? parseFloat(row.amount) : 0,
      worked_today: row.worked_today === 'true' || row.worked_today === '1' ? 1 : 0
    };

    // Add counts only if they exist and are valid numbers
    if (row.client_count && !isNaN(parseInt(row.client_count))) {
      processed.client_count = parseInt(row.client_count);
    }
    if (row.product_count && !isNaN(parseInt(row.product_count))) {
      processed.product_count = parseInt(row.product_count);
    }

    // Add time fields only if they exist
    if (row.start_time) processed.start_time = row.start_time;
    if (row.end_time) processed.end_time = row.end_time;
    if (row.hours_worked && !isNaN(parseFloat(row.hours_worked))) {
      processed.hours_worked = parseFloat(row.hours_worked);
    }

    // Set payment type values
    const paymentType = row.payment_type.toLowerCase();
    processed.cash = paymentType === 'cash' ? parseFloat(row.amount) : 0;
    processed.card = paymentType === 'card' ? parseFloat(row.amount) : 0;
    processed.voucher = paymentType === 'voucher' ? parseFloat(row.amount) : 0;

    return processed;
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

   const handleDownloadTemplate = () => {
    const templateContent = 'date,employee_id,sales_type,amount,payment_type,client_count,product_count,worked_today,start_time,end_time,hours_worked\n' +
      '05/01/2024,1,service,150.00,card,3,0,true,09:00,17:00,8\n' +
      '05/01/2024,2,product,50.00,cash,1,2,true,,';  // Example with missing optional fields

    const blob = new Blob([templateContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'income_import_template.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    // Reset previous results and errors
    setValidationErrors([]);
    setImportResults(null);

    Papa.parse(file, {
      header: true,
      skipEmptyRows: true,
      complete: async (results) => {
        const allErrors = [];
        const validData = [];

        // Validate each row
        results.data.forEach((row, index) => {
          if (Object.values(row).every(value => !value)) {
            return;
          }

          const rowErrors = validateIncomeData(row);
          if (rowErrors.length > 0) {
            allErrors.push(`Row ${index + 1}: ${rowErrors.join(', ')}`);
          } else {
            validData.push(processIncomeData(row));
          }
        });

        if (allErrors.length > 0) {
          setValidationErrors(allErrors);
          setMessage('Validation errors found. Please check the errors below and try again.');
          setMessageType('error');
          return;
        }

        try {
          const response = await api.post('/api/import_income', validData);
          const results = response.data;
          setImportResults(results);

          if (results.successful_imports === 0) {
            setMessageType('error');
            if (results.errors && results.errors.length > 0) {
              setValidationErrors(results.errors);
              setMessage('Import failed. Please check the errors below.');
            } else {
              setMessage('Import failed. No income records were imported.');
            }
          } else {
            if (results.failed_imports > 0) {
              setMessageType('warning');
              setMessage(`Imported ${results.successful_imports} records with ${results.failed_imports} failures.`);
              if (results.errors) {
                setValidationErrors(results.errors);
              }
            } else {
              setMessageType('success');
              setMessage(`Successfully imported ${results.successful_imports} income records!`);
            }
          }

          // Clear file input
          event.target.value = '';

        } catch (error) {
          console.error('Error uploading file:', error);
          setMessageType('error');
          if (error.response?.data?.errors) {
            setValidationErrors(error.response.data.errors);
            setMessage('Import failed. Please check the errors below.');
          } else {
            setMessage('Error importing income records. Please try again.');
          }
        }
      },
      error: (error) => {
        console.error('Error parsing CSV:', error);
        setMessage('Error reading CSV file. Please check the format and try again.');
        setMessageType('error');
      }
    });
  };

  return (
    <Paper elevation={3} sx={{ p: 3, maxWidth: 1200, margin: '0 auto' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h5">Income Import</Typography>
      </Box>

      <Box sx={{ mb: 3 }}>
        <Tabs 
          value={activeTab} 
          onChange={handleTabChange}
          sx={{
            borderBottom: 1,
            borderColor: 'divider'
          }}
        >
          <Tab label="Import Instructions" value="INSTRUCTIONS" />
          <Tab label="Import" value="IMPORT" />
        </Tabs>
      </Box>

      {activeTab === 'INSTRUCTIONS' && (
        <Box>
          <Typography variant="h6" gutterBottom>Before You Import</Typography>
          <Alert severity="info" sx={{ mb: 3 }}>
            <Typography variant="subtitle2">Required Fields:</Typography>
            <ul>
              <li>Date (DD/MM/YYYY format)</li>
              <li>Employee ID</li>
              <li>Sales Type (service or product)</li>
              <li>Amount</li>
              <li>Payment Type (cash, card, or voucher)</li>
            </ul>
          </Alert>

          <Alert severity="warning" sx={{ mb: 3 }}>
            <Typography variant="subtitle2">Optional Fields:</Typography>
            <ul>
              <li>Client Count</li>
              <li>Product Count</li>
              <li>Worked Today (true/false)</li>
              <li>Start Time (HH:MM format)</li>
              <li>End Time (HH:MM format)</li>
              <li>Hours Worked</li>
            </ul>
          </Alert>

          <Typography variant="body2" sx={{ mb: 2 }}>
            For historical data imports, optional fields can be left blank if the information is not available.
          </Typography>
        </Box>
      )}

      {activeTab === 'IMPORT' && (
        <Box>
          <Box>
            <Typography variant="subtitle1" gutterBottom>Import Instructions</Typography>
            <Alert severity="info" sx={{ mb: 3 }}>
              <Typography variant="body2">
                1. Download the template below
                <br />
                2. Fill in your income data following the format requirements
                <br />
                3. Save and upload your completed file
              </Typography>
            </Alert>

            {validationErrors.length > 0 && (
              <Alert severity="error" sx={{ mb: 3 }}>
                <Typography variant="subtitle2">Validation Errors:</Typography>
                <ul>
                  {validationErrors.map((error, index) => (
                    <li key={index}>{error}</li>
                  ))}
                </ul>
              </Alert>
            )}

            {importResults && (
              <Alert 
                severity={importResults.successful_imports > 0 ? 
                  (importResults.failed_imports > 0 ? 'warning' : 'success') : 
                  'error'
                } 
                sx={{ mb: 3 }}
              >
                <Typography variant="subtitle2">Import Results:</Typography>
                <ul>
                  <li>Successfully imported: {importResults.successful_imports}</li>
                  {importResults.failed_imports > 0 && (
                    <li>Failed imports: {importResults.failed_imports}</li>
                  )}
                </ul>
              </Alert>
            )}

            <Button
              startIcon={<DownloadIcon />}
              variant="outlined"
              onClick={handleDownloadTemplate}
              sx={{ mt: 2, mb: 4 }}
            >
              DOWNLOAD TEMPLATE
            </Button>
          </Box>

          <Box>
            <Typography variant="subtitle1" gutterBottom>Import Data</Typography>
            <input
              type="file"
              accept=".csv"
              onChange={handleFileUpload}
              style={{ display: 'none' }}
              id="csv-file-input"
            />
            <label htmlFor="csv-file-input">
              <Button
                startIcon={<UploadIcon />}
                variant="contained"
                component="span"
              >
                Choose CSV File
              </Button>
            </label>
          </Box>
        </Box>
      )}
    </Paper>
  );
};

export default IncomeImport;