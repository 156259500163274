import React, { useState } from 'react';
import api from './axiosConfig';
import errorHandler from './utils/errorHandler';
import './adminstyles.css';

const PasswordRecovery = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

   const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setError('');
    setMessage('');
    
    try {
        console.log('Sending request to:', '/auth/request_password_reset');
        const response = await api.post('/auth/request_password_reset', { email });
        
        console.log('Response received:', response);
        setMessage('If an account exists with this email, you will receive a password reset link.');
        errorHandler.logDebug('PasswordRecovery', 'Password reset request sent');
    } catch (error) {
        console.error('Full error:', error);
        setError(error.response?.data?.error || 'Error requesting password reset');
        errorHandler.logError('PasswordRecovery', 'Password reset request failed', error);
    } finally {
        setIsLoading(false);
    }
};

    return (
        <div className="password-recovery-container">
            <form onSubmit={handleSubmit}>
                <h2>Request Password Reset</h2>
                <div className="form-group">
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter your email"
                        required
                        disabled={isLoading}
                    />
                </div>
                <button type="submit" disabled={isLoading}>
                    {isLoading ? 'Sending...' : 'Request Password Reset'}
                </button>
                {message && <div className="success-message">{message}</div>}
                {error && <div className="error-message">{error}</div>}
            </form>
        </div>
    );
};

export default PasswordRecovery;