import React, { useState, useEffect } from 'react';
import { useAuth } from './AuthContext';
import api from './axiosConfig';
import {
  Box,
  Grid,
  Paper,
  Typography,
  CircularProgress,
  Alert,
  Card,
  CardContent,
  Tabs,
  Tab,
  Button,
  Chip,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Snackbar,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField
} from '@mui/material';

import UploadFileIcon from '@mui/icons-material/UploadFile';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';


const RentalDashboard = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [rentals, setRentals] = useState([]);
  const [selectedRental, setSelectedRental] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [showNewRenterDialog, setShowNewRenterDialog] = useState(false);
  const [editRenterData, setEditRenterData] = useState(null);

  const isAdmin = user?.role === 'ACCOUNT_OWNER' || user?.role === 'ACCOUNT_MANAGER';

  const fetchDocuments = async (rentalId) => {
    if (!rentalId) return;
    try {
      const response = await api.get(`/rentals/documents/${rentalId}`);
      setDocuments(response.data);
    } catch (error) {
      console.error('Error fetching documents:', error);
    }
  };

  const fetchInvoices = async (rentalId) => {
    if (!rentalId) return;
    try {
      const response = await api.get(`/rentals/invoices/${rentalId}`);
      setInvoices(response.data);
    } catch (error) {
      console.error('Error fetching invoices:', error);
    }
  };

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      try {
        const response = await api.get('/rentals/data');
        
        if (user.role === 'RENTAL_USER') {
          setRentals([response.data]);
          setSelectedRental(response.data);
          
          if (response.data?.id) {
            await Promise.all([
              fetchDocuments(response.data.id),
              fetchInvoices(response.data.id)
            ]);
          }
        } else {
          setRentals(response.data);
          if (response.data?.length > 0) {
            setSelectedRental(response.data[0]);
            await Promise.all([
              fetchDocuments(response.data[0].id),
              fetchInvoices(response.data[0].id)
            ]);
          }
        }
      } catch (error) {
        console.error('Error loading rental data:', error);
        setError('Error loading rental data');
        setSnackbar({
          open: true,
          message: 'Error fetching rental data',
          severity: 'error'
        });
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [user?.role]);

  const handleRentalChange = (event) => {
    const rental = rentals.find(r => r.id === event.target.value);
    setSelectedRental(rental);
    if (rental?.id) {
      fetchDocuments(rental.id);
      fetchInvoices(rental.id);
    }
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (!file || !selectedRental) return;

    const allowedTypes = [
      'application/pdf',
      'image/jpeg',
      'image/png',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ];

    if (!allowedTypes.includes(file.type)) {
      setSnackbar({
        open: true,
        message: 'Please upload a PDF, JPEG, PNG, or Word document',
        severity: 'error'
      });
      return;
    }

    const formData = new FormData();
    formData.append('document', file);
    formData.append('rental_id', selectedRental.id);

    try {
      const response = await api.post('/rentals/upload_document', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });

      if (response.data) {
        setSnackbar({
          open: true,
          message: 'Document uploaded successfully',
          severity: 'success'
        });
        await fetchDocuments(selectedRental.id);
      }
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Error uploading document',
        severity: 'error'
      });
    }
  };

  const handleEditRenter = (rental) => {
    setEditRenterData(rental);
    setShowNewRenterDialog(true);
  };

 const handleSaveRenter = async (renterData) => {
  try {
    // Optimistically update the UI
    const optimisticRentals = rentals.map(rental => 
      rental.id === renterData.id ? { ...rental, ...renterData } : rental
    );
    if (!renterData.id) {
      // If it's a new rental, add it to the list
      optimisticRentals.push({ ...renterData, id: 'temp-' + Date.now() });
    }
    setRentals(optimisticRentals);
    setShowNewRenterDialog(false);
    setEditRenterData(null);

    // Make API call
    const response = await api.post('/rentals/save', renterData);
    if (response.data) {
      // Refresh data in background
      const dataResponse = await api.get('/rentals/data');
      setRentals(dataResponse.data);
      
      setSnackbar({
        open: true,
        message: `Renter ${editRenterData ? 'updated' : 'added'} successfully`,
        severity: 'success'
      });
    }
  } catch (error) {
    console.error('Error saving renter:', error);
    // Revert optimistic update
    const dataResponse = await api.get('/rentals/data');
    setRentals(dataResponse.data);
    
    setSnackbar({
      open: true,
      message: 'Error saving renter',
      severity: 'error'
    });
  }
};

  const RentalOverview = () => (
    <Grid container spacing={3}>
      <Grid item xs={12} md={4}>
        <Card sx={{ height: '100%', minHeight: '250px' }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>Rental Details</Typography>
            {isAdmin ? (
              <Box>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <InputLabel>Select Renter</InputLabel>
                  <Select
                    value={selectedRental?.id || ''}
                    onChange={handleRentalChange}
                  >
                    {rentals.map((rental) => (
                      <MenuItem key={rental.id} value={rental.id}>
                        {rental.name || rental.address1}
                        <Chip 
                          size="small"
                          label={rental.active ? 'Active' : 'Inactive'}
                          color={rental.active ? 'success' : 'error'}
                          sx={{ ml: 1 }}
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => setShowNewRenterDialog(true)}
                  startIcon={<AddIcon />}
                >
                  Add New Renter
                </Button>
              </Box>
            ) : (
              <>
                <Typography>Salon: {selectedRental.address1}</Typography>
                <Typography>Rent: £{selectedRental.rentCost}/month</Typography>
                <Typography>Start Date: {new Date(selectedRental.dateRentalCommenced).toLocaleDateString()}</Typography>
                <Typography>
                  Status:
                  <Chip 
                    label={selectedRental.active ? 'Active' : 'Inactive'}
                    color={selectedRental.active ? 'success' : 'error'}
                    size="small"
                    sx={{ ml: 1 }}
                  />
                </Typography>
              </>
            )}
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} md={4}>
        <Card sx={{ height: '100%', minHeight: '250px' }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>Contact Information</Typography>
            <Typography>Phone: {selectedRental.phoneNumber}</Typography>
            <Typography>Emergency Contact: {selectedRental.emergencyContactName}</Typography>
            <Typography>Emergency Number: {selectedRental.emergencyContactNumber}</Typography>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} md={4}>
        <Card sx={{ height: '100%', minHeight: '250px' }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>Recent Activity</Typography>
            <Typography>Latest Document: {documents[0]?.name || 'No documents'}</Typography>
            <Typography>Latest Invoice: {
              invoices[0] ? 
                `Due ${new Date(invoices[0].due_date).toLocaleDateString()}` : 
                'No invoices'
            }</Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );

  const DocumentsTab = () => (
    <Box>
      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6">Documents</Typography>
        <Button
          variant="contained"
          startIcon={<UploadFileIcon />}
          onClick={() => document.getElementById('document-upload').click()}
        >
          Upload Document
        </Button>
        <input
          id="document-upload"
          type="file"
          hidden
          onChange={handleFileUpload}
          accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
        />
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Document Name</TableCell>
              <TableCell>Upload Date</TableCell>
              <TableCell>Reminder Date</TableCell>
              <TableCell>Comments</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {documents.length === 0 ? (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <Typography variant="body2" color="textSecondary">
                    No documents uploaded yet
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              documents.map((doc) => (
                <TableRow key={doc.id}>
                  <TableCell>{doc.name}</TableCell>
                  <TableCell>
                    {doc.upload_date ? new Date(doc.upload_date).toLocaleDateString() : '-'}
                  </TableCell>
                  <TableCell>
                    {doc.reminder_date ? new Date(doc.reminder_date).toLocaleDateString() : '-'}
                  </TableCell>
                  <TableCell>{doc.comment || '-'}</TableCell>
                  <TableCell>
                    <Chip
                      label={doc.status}
                      color={
                        doc.status === 'ACCEPTED' ? 'success' :
                        doc.status === 'DENIED' ? 'error' :
                        'default'
                      }
                      size="small"
                    />
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => window.open(doc.file_path, '_blank')}
                    >
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );

  const InvoicesTab = () => (
    <Box>
      <Typography variant="h6" gutterBottom>Invoices</Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Invoice Date</TableCell>
              <TableCell>Due Date</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoices.map((invoice) => (
              <TableRow key={invoice.id}>
                <TableCell>{new Date(invoice.invoice_date).toLocaleDateString()}</TableCell>
                <TableCell>{new Date(invoice.due_date).toLocaleDateString()}</TableCell>
                <TableCell>£{invoice.amount_due}</TableCell>
                <TableCell>
                  <Chip
                    label={invoice.status}
                    color={invoice.status === 'PAID' ? 'success' : 'warning'}
                    size="small"
                  />
                </TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => window.open(`/api/rentals/invoice/${invoice.id}`, '_blank')}
                  >
                    Download
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );

  const RenterManagement = () => (
  <Box>
    <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Typography variant="h6">Manage Renters</Typography>
      <Button
        variant="contained"
        startIcon={<AddIcon />}
        onClick={() => {
          setEditRenterData(null);
          setShowNewRenterDialog(true);
        }}
      >
        Add New Renter
      </Button>
    </Box>
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Address</TableCell>
            <TableCell>Salon</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Start Date</TableCell>
            <TableCell>Monthly Rent</TableCell>
            <TableCell>Phone</TableCell>
            <TableCell>Rent Period</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rentals.map((rental) => (
            <TableRow key={rental.id}>
              <TableCell>{rental.name}</TableCell>
              <TableCell>
                {rental.address2 && `${rental.address2}, `}
                {rental.address3 && `${rental.address3}, `}
                {rental.postcode}
              </TableCell>
              <TableCell>{rental.address1}</TableCell>
              <TableCell>
                <Chip 
                  label={rental.active ? 'Active' : 'Inactive'}
                  color={rental.active ? 'success' : 'error'}
                  size="small"
                />
              </TableCell>
              <TableCell>
                {rental.dateRentalCommenced ? 
                  new Date(rental.dateRentalCommenced).toLocaleDateString() : '-'}
              </TableCell>
              <TableCell>£{rental.rentCost}</TableCell>
              <TableCell>{rental.phoneNumber}</TableCell>
              <TableCell>{rental.rentPeriod}</TableCell>
              <TableCell>
                <Box sx={{ display: 'flex', gap: 1 }}>
                  <Button 
                    size="small" 
                    variant="outlined" 
                    startIcon={<EditIcon />}
                    onClick={() => handleEditRenter(rental)}
                  >
                    Edit
                  </Button>
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </Box>
);

  const RenterDialog = () => {
  const [formData, setFormData] = useState(
    editRenterData || {
      name: '',
      address1: '',
      address2: '',
      address3: '',
      postcode: '',
      phoneNumber: '',
      emergencyContactName: '',
      emergencyContactNumber: '',
      rentCost: '',
      rentPeriod: '',
      turnoverRentPercentage: '',
      dateRentalCommenced: '',
      invoiceOffset: '',
      active: true,
      id: null
    }
  );

  useEffect(() => {
    if (editRenterData) {
      setFormData({
        ...editRenterData,
        dateRentalCommenced: editRenterData.dateRentalCommenced ? 
          new Date(editRenterData.dateRentalCommenced).toISOString().split('T')[0] : '',
        rentCost: editRenterData.rentCost || '',
        turnoverRentPercentage: editRenterData.turnoverRentPercentage || '',
        invoiceOffset: editRenterData.invoiceOffset || ''
      });
    }
  }, [editRenterData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <Dialog 
      open={showNewRenterDialog} 
      onClose={() => {
        setShowNewRenterDialog(false);
        setEditRenterData(null);
      }}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        {editRenterData ? 'Edit Renter' : 'Add New Renter'}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} md={6}>
            <TextField
              required
              fullWidth
              label="Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              fullWidth
              label="Salon Address"
              name="address1"
              value={formData.address1}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Address Line 2"
              name="address2"
              value={formData.address2}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Address Line 3"
              name="address3"
              value={formData.address3}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              fullWidth
              label="Postcode"
              name="postcode"
              value={formData.postcode}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              fullWidth
              label="Phone Number"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              fullWidth
              label="Emergency Contact Name"
              name="emergencyContactName"
              value={formData.emergencyContactName}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              fullWidth
              label="Emergency Contact Number"
              name="emergencyContactNumber"
              value={formData.emergencyContactNumber}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              fullWidth
              label="Monthly Rent"
              name="rentCost"
              type="number"
              value={formData.rentCost}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              fullWidth
              label="Rent Period"
              name="rentPeriod"
              value={formData.rentPeriod}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Turnover Rent Percentage"
              name="turnoverRentPercentage"
              type="number"
              value={formData.turnoverRentPercentage}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              fullWidth
              label="Start Date"
              name="dateRentalCommenced"
              type="date"
              value={formData.dateRentalCommenced}
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Invoice Offset (days)"
              name="invoiceOffset"
              type="number"
              value={formData.invoiceOffset}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel>Status</InputLabel>
              <Select
                name="active"
                value={formData.active}
                onChange={handleChange}
                label="Status"
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {
          setShowNewRenterDialog(false);
          setEditRenterData(null);
        }}>
          Cancel
        </Button>
        <Button onClick={() => handleSaveRenter(formData)} variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  if (!selectedRental) {
    return (
      <Paper elevation={3} sx={{ p: 3, maxWidth: 1200, margin: '0 auto' }}>
        <Typography>No rental information found.</Typography>
      </Paper>
    );
  }

  return (
    <Paper elevation={3} sx={{ p: 3, maxWidth: 1200, margin: '0 auto' }}>
      <Typography variant="h4" gutterBottom>
        {isAdmin ? 'Rental Management' : 'My Rental'}
      </Typography>

      <Paper sx={{ mt: 3 }}>
        <Tabs value={activeTab} onChange={(e, newValue) => setActiveTab(newValue)}>
          <Tab label="Overview" />
          <Tab label="Documents" />
          <Tab label="Invoices" />
          {isAdmin && <Tab label="Manage Renters" />}
        </Tabs>

        <Box sx={{ p: 3 }}>
          {activeTab === 0 && <RentalOverview />}
          {activeTab === 1 && <DocumentsTab />}
          {activeTab === 2 && <InvoicesTab />}
          {activeTab === 3 && isAdmin && <RenterManagement />}
        </Box>
      </Paper>

      <RenterDialog />

      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
      >
        <Alert 
          onClose={() => setSnackbar(prev => ({ ...prev, open: false }))} 
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default RentalDashboard;