import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Typography,
  Grid,
  Button,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Divider,
  CircularProgress,
  Alert,
  IconButton,
  Badge,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Snackbar
} from '@mui/material';
import {
  Email as EmailIcon,
  Delete as DeleteIcon,
  Send as SendIcon,
  MarkunreadMailbox as UnreadIcon,
  MarkEmailRead as ReadIcon,
  NotificationsActive as PriorityHighIcon
} from '@mui/icons-material';
import { useAuth } from './AuthContext';
import api from './axiosConfig';
import { io } from 'socket.io-client';

const MessagesDashboard = () => {
  const { user } = useAuth();
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [composeOpen, setComposeOpen] = useState(false);
  const [recipients, setRecipients] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [notification, setNotification] = useState({ open: false, message: '', severity: 'info' });
  const [newMessage, setNewMessage] = useState({
    recipient_ids: [],
    type: 'DIRECT_MESSAGE',
    title: '',
    content: '',
    priority: 'NORMAL'
  });
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const newSocket = io('/', {
      path: '/socket.io'
    });
    
    setSocket(newSocket);

    newSocket.on('connect', () => {
      showNotification('Connected to message service', 'success');
    });

    newSocket.on('disconnect', () => {
      showNotification('Disconnected from message service', 'warning');
    });

    newSocket.on('new_message', handleNewMessage);
    newSocket.on('message_update', handleMessageUpdate);
    newSocket.on('message_deleted', handleMessageDeleted);
    newSocket.on('error', handleSocketError);

    fetchInitialData();

    return () => newSocket.disconnect();
  }, []);

  const fetchInitialData = async () => {
    try {
      const [messagesResponse, recipientsResponse, unreadResponse] = await Promise.all([
        api.get('/messages/'),
        api.get('/messages/available-recipients'),
        api.get('/messages/unread-count')
      ]);

      setMessages(messagesResponse.data);
      setRecipients(recipientsResponse.data);
      setUnreadCount(unreadResponse.data.count);
    } catch (err) {
      setError('Failed to load initial data');
      showNotification('Failed to load messages', 'error');
    } finally {
      setLoading(false);
    }
  };

  const showNotification = (message, severity = 'info') => {
    setNotification({
      open: true,
      message,
      severity
    });
  };

  const handleNewMessage = (message) => {
    setMessages(prev => [message, ...prev]);
    setUnreadCount(prev => prev + 1);
    showNotification(`New message from ${message.sender.name}`, 'info');
  };

  const handleMessageUpdate = (update) => {
    setMessages(prev => 
      prev.map(msg => 
        msg.secure_id === update.secure_id ? { ...msg, ...update } : msg
      )
    );
  };

  const handleMessageDeleted = (messageId) => {
    setMessages(prev => prev.filter(msg => msg.secure_id !== messageId));
  };

  const handleSocketError = (error) => {
    showNotification(error.message || 'Connection error occurred', 'error');
  };

  const handleSendMessage = async () => {
    if (!newMessage.recipient_ids.length || !newMessage.content.trim()) {
      showNotification('Please select recipients and enter a message', 'warning');
      return;
    }

    try {
      const response = await api.post('/messages/', newMessage);
      setComposeOpen(false);
      setNewMessage({
        recipient_ids: [],
        type: 'DIRECT_MESSAGE',
        title: '',
        content: '',
        priority: 'NORMAL'
      });
      showNotification('Message sent successfully', 'success');
      socket?.emit('message_sent', response.data);
      fetchInitialData();
    } catch (err) {
      showNotification('Failed to send message', 'error');
    }
  };

  const handleMarkAsRead = async (secureId) => {
    try {
      await api.put(`/messages/${secureId}/read`);
      setMessages(prev =>
        prev.map(msg =>
          msg.secure_id === secureId ? { ...msg, status: 'READ' } : msg
        )
      );
      setUnreadCount(prev => Math.max(0, prev - 1));
    } catch (err) {
      showNotification('Failed to mark message as read', 'error');
    }
  };

  const handleDeleteMessage = async (secureId) => {
    try {
      await api.delete(`/messages/${secureId}`);
      setMessages(prev => prev.filter(msg => msg.secure_id !== secureId));
      showNotification('Message deleted', 'success');
      socket?.emit('message_deleted', secureId);
    } catch (err) {
      showNotification('Failed to delete message', 'error');
    }
  };

  const getPriorityIcon = (priority) => {
    switch (priority) {
      case 'URGENT':
        return <PriorityHighIcon color="error" />;
      case 'HIGH':
        return <PriorityHighIcon color="warning" />;
      default:
        return null;
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Typography variant="h6">
                Messages 
                {unreadCount > 0 && (
                  <Badge 
                    badgeContent={unreadCount} 
                    color="error" 
                    sx={{ ml: 2 }}
                  >
                    <UnreadIcon />
                  </Badge>
                )}
              </Typography>
              <Button
                variant="contained"
                startIcon={<EmailIcon />}
                onClick={() => setComposeOpen(true)}
              >
                New Message
              </Button>
            </Box>
            
            {error && (
              <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>
            )}

            <List>
              {messages.map((message) => (
                <React.Fragment key={message.secure_id}>
                  <ListItem
                    button
                    onClick={() => {
                      setSelectedMessage(message);
                      if (message.status === 'UNREAD') {
                        handleMarkAsRead(message.secure_id);
                      }
                    }}
                    sx={{
                      bgcolor: message.status === 'UNREAD' ? 'action.hover' : 'inherit',
                      '&:hover': {
                        bgcolor: 'action.selected',
                      },
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar>
                        {message.status === 'UNREAD' ? <UnreadIcon /> : <ReadIcon />}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          {getPriorityIcon(message.priority)}
                          <Typography
                            component="span"
                            variant="subtitle1"
                            sx={{ fontWeight: message.status === 'UNREAD' ? 'bold' : 'normal' }}
                          >
                            {message.title}
                          </Typography>
                        </Box>
                      }
                      secondary={
                        <>
                          <Typography component="span" variant="body2">
                            From: {message.sender.name}
                          </Typography>
                          <br />
                          <Typography component="span" variant="body2">
                            {new Date(message.created_at).toLocaleString()}
                          </Typography>
                        </>
                      }
                    />
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteMessage(message.secure_id);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItem>
                  <Divider />
                </React.Fragment>
              ))}
            </List>
          </Paper>
        </Grid>
      </Grid>

      {/* Compose Message Dialog */}
      <Dialog open={composeOpen} onClose={() => setComposeOpen(false)} maxWidth="md" fullWidth>
        <DialogTitle>New Message</DialogTitle>
        <DialogContent>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Recipients</InputLabel>
            <Select
              multiple
              value={newMessage.recipient_ids}
              onChange={(e) => setNewMessage({ ...newMessage, recipient_ids: e.target.value })}
            >
              {recipients.map((recipient) => (
                <MenuItem key={recipient.id} value={recipient.id}>
                  {recipient.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            fullWidth
            label="Title"
            value={newMessage.title}
            onChange={(e) => setNewMessage({ ...newMessage, title: e.target.value })}
            sx={{ mt: 2 }}
          />
          <TextField
            fullWidth
            label="Message"
            multiline
            rows={4}
            value={newMessage.content}
            onChange={(e) => setNewMessage({ ...newMessage, content: e.target.value })}
            sx={{ mt: 2 }}
          />
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Priority</InputLabel>
            <Select
              value={newMessage.priority}
              onChange={(e) => setNewMessage({ ...newMessage, priority: e.target.value })}
            >
              <MenuItem value="LOW">Low</MenuItem>
              <MenuItem value="NORMAL">Normal</MenuItem>
              <MenuItem value="HIGH">High</MenuItem>
              <MenuItem value="URGENT">Urgent</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setComposeOpen(false)}>Cancel</Button>
          <Button 
            onClick={handleSendMessage} 
            variant="contained" 
            startIcon={<SendIcon />}
            disabled={!newMessage.recipient_ids.length || !newMessage.content.trim()}
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>

      {/* View Message Dialog */}
      <Dialog 
        open={!!selectedMessage} 
        onClose={() => setSelectedMessage(null)}
        maxWidth="md" 
        fullWidth
      >
        {selectedMessage && (
          <>
            <DialogTitle>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                {getPriorityIcon(selectedMessage.priority)}
                {selectedMessage.title}
              </Box>
            </DialogTitle>
            <DialogContent>
              <Typography variant="subtitle2" color="textSecondary">
                From: {selectedMessage.sender.name}
              </Typography>
              <Typography variant="subtitle2" color="textSecondary">
                Date: {new Date(selectedMessage.created_at).toLocaleString()}
              </Typography>
              <Typography variant="subtitle2" color="textSecondary">
                Priority: {selectedMessage.priority.toLowerCase()}
              </Typography>
              <Typography variant="body1" sx={{ mt: 2 }}>
                {selectedMessage.content}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setSelectedMessage(null)}>Close</Button>
            </DialogActions>
          </>
        )}
      </Dialog>

      {/* Notification Snackbar */}
      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={() => setNotification({ ...notification, open: false })}
      >
        <Alert 
          onClose={() => setNotification({ ...notification, open: false })} 
          severity={notification.severity}
          sx={{ width: '100%' }}
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default MessagesDashboard;